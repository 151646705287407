import { useState, useEffect, useCallback } from "react";
import API from "../../../config/axiosConfig";

/**
 * Custom hook for fetching training topics
 * @param {string} selectedHospitalId - ID of the selected hospital
 * @returns {Object} Object containing topics data, loading state, error state, raw data, and refresh function
 */
const useTrainingTopics = (selectedHospitalId) => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rawTopicsData, setRawTopicsData] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const refreshTopics = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  useEffect(() => {
    const fetchTopics = async () => {
      if (!selectedHospitalId) {
        setTopics([]);
        setLoading(false);
        return;
      }
      
      setLoading(true);
      try {
        const endpoint = `/api/trainings/training-topics/hospital/${selectedHospitalId}`;
        const response = await API.get(endpoint);
        const data = response.data;
        
        const processedTopics = data.map(topic => ({
          ...topic,
          id: topic._id || topic.id,
        }));
        
        setRawTopicsData(data);
        setTopics(processedTopics);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching topics:", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          setTopics([]);
          setError("No permission to view topics for this hospital");
        } else {
          setError(err.response?.data?.message || "Failed to fetch topics");
        }
        setLoading(false);
      }
    };

    fetchTopics();
  }, [selectedHospitalId, refreshTrigger]);

  return { topics, loading, error, rawTopicsData, refreshTopics };
};

export default useTrainingTopics;