import React, { useState } from 'react';
import { ProtocolDefinition, DefiDefinition} from './types';
import resusDrugsDefinition from './data/resus-drugs-definitions.json';
import { faTrash, faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface ProtocolFormProps {
  onSubmit: (protocol: ProtocolDefinition) => void;
  onCancel: () => void;
  editingProtocol?: ProtocolDefinition | null|undefined;
}

const ProtocolForm: React.FC<ProtocolFormProps> = ({ onSubmit, onCancel, editingProtocol }) => {
  const [drugs, setDrugs] = useState<string[]>(editingProtocol?.drugs || []);
  const [drips, setDrips] = useState<string[]>(editingProtocol?.drips || []);
  const [defi, setDefi] = useState<DefiDefinition[]>(editingProtocol?.defi || []);
  const [editingItem, setEditingItem] = useState<{ type: 'drug' | 'drip', name: string } | null>(null);

    // Get available drugs and drips from resus-drugs-definition.json
    const availableDrugs = resusDrugsDefinition.drugs.map(drug => drug.id);
    const availableDrips = resusDrugsDefinition.drips.map(drip => drip.id);

  const [drugComments, setDrugComments] = useState<{ [key: string]: string }>(() => {
    const initialDrugComments: { [key: string]: string } = {};

    if (editingProtocol && editingProtocol.drugComments) {
        return {...editingProtocol.drugComments};
      }
    return initialDrugComments;
  });
  
  // console.log ('*** drug', {drugComments});
  // console.log ('*** drugs', {drugs});

  // State for new item inputs
  const [selectedDrug, setSelectedDrug] = useState('');
  const [selectedDrip, setSelectedDrip] = useState('');
  const [newDefi, setNewDefi] = useState({ name: '', joulePerKg: 0 });
  const [tempComment, setTempComment] = useState('');


  const addDrug = () => {
    if (selectedDrug && !drugs.includes(selectedDrug)) {
      setDrugs([...drugs, selectedDrug]);
      setSelectedDrug('');
    }
  };

  const addDrip = () => {
    if (selectedDrip && !drips.includes(selectedDrip)) {
      setDrips([...drips, selectedDrip]);
      setSelectedDrip('');
    }
  };

  const addDefi = () => {
    if (newDefi.name.trim()) {
      setDefi([...defi, { ...newDefi }]);
      setNewDefi({ name: '', joulePerKg: 0 });
    }
  };

  const removeDefi = (index: number) => {
    setDefi(defi.filter((_, i) => i !== index));
  };

  const startEditing = (type: 'drug' | 'drip', name: string) => {
    setEditingItem({ type, name });
    setTempComment(drugComments[name] || '');
  };

  const saveComment = () => {
    if (editingItem) {
      console.log ('*** save comment',{tempComment})
      setDrugComments({
        ...drugComments,
        [editingItem.name]: tempComment
      });
      setEditingItem(null);
    }
  };

  const cancelEditing = () => {
    setEditingItem(null);
    setTempComment('');
  };

  const removeItem = (type: 'drug' | 'drip', name: string) => {
    if (type === 'drug') {
      setDrugs(drugs.filter(drug => drug !== name));
    } else {
      setDrips(drips.filter(drip => drip !== name));
    }
    const newComments = { ...drugComments };
    delete newComments[name];
    setDrugComments(newComments);
  };

const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
  
    const newProtocol: ProtocolDefinition = {
      protocolId: formData.get('protocolId') as string,
      drugs,
      ...(drips.length > 0 && { drips }),
      ...(defi.length > 0 && { defi }),
      ...(Object.keys(drugComments).length > 0 && { drugComments }) // Ensure comments are saved
    };
  
    onSubmit(newProtocol);
  };
  
  const renderTable = (type: 'drug' | 'drip', items: string[]) => {
    const availableItems = type === 'drug' ? availableDrugs : availableDrips;
    const selectedValue = type === 'drug' ? selectedDrug : selectedDrip;
    const setSelectedValue = type === 'drug' ? setSelectedDrug : setSelectedDrip;
    const addItem = type === 'drug' ? addDrug : addDrip;
    
    // Filter out items that are already added
    const remainingItems = availableItems.filter(item => !items.includes(item)).sort((a, b) => a.localeCompare(b));
    
    return (
    <div className="mb-4">
      <h5>{type === 'drug' ? 'Drugs' : 'Drips'}</h5>
      { items[0] && (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>שם</th>
            <th>הערות</th>
            <th>פעולות</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item}>
              <td>{item}</td>
              <td>
                {editingItem?.name === item ? (
                  <input
                    type="text"
                    className="form-control"
                    value={tempComment}
                    onChange={(e) => setTempComment(e.target.value)}
                  />
                ) : (
                  drugComments[item] || ''
                )}
              </td>
              <td>
                <div className="d-flex gap-2">
                  {editingItem?.name === item ? (
                    <>
                      <button 
                        type="button" 
                        className="btn btn-sm btn-success"
                        onClick={saveComment}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                      <button 
                        type="button" 
                        className="btn btn-sm btn-secondary"
                        onClick={cancelEditing}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </>
                  ) : (
                    <>
                      <button 
                        type="button" 
                        className="btn btn-sm btn-primary"
                        onClick={() => startEditing(type, item)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                      <button 
                        type="button" 
                        className="btn btn-sm btn-danger"
                        onClick={() => removeItem(type, item)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      )}
      <div className="input-group mb-3">
      <select
            className="form-select"
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
          >
            <option value="">Select {type}...</option>
            {remainingItems.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <button 
            type="button" 
            className="btn btn-outline-secondary"
            onClick={addItem}
            disabled={!selectedValue}
          >
          Add {type === 'drug' ? 'Drug' : 'Drip'}
        </button>
      </div>
    </div>
  )};

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="form-label">Protocol ID</label>
        <input
          name="protocolId"
          className="form-control"
          required
          defaultValue={editingProtocol?.protocolId || ''}
        />
      </div>

      {renderTable('drug', drugs)}
      {renderTable('drip', drips)}

      {/* Defi Section */}
      <div className="mb-4">
        <h5>Defibrillation</h5>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Defi name"
            value={newDefi.name}
            onChange={(e) => setNewDefi({ ...newDefi, name: e.target.value })}
          />
          <input
            type="number"
            className="form-control"
            placeholder="Joules per kg"
            value={newDefi.joulePerKg || ''}
            onChange={(e) => setNewDefi({ ...newDefi, joulePerKg: parseFloat(e.target.value) })}
          />
          <button type="button" className="btn btn-outline-secondary" onClick={addDefi}>
            Add Defi
          </button>
        </div>
        <div className="list-group">
          {defi.map((def, index) => (
            <div key={index} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                <strong>{def.name}</strong>
                <span className="ms-2">{def.joulePerKg} J/kg</span>
              </div>
              <div className="btn-group" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <FontAwesomeIcon 
                    icon={faTrash} 
                    style={{ cursor: 'pointer' }}
                    onClick={() => removeDefi(index)} // Delete the selected medicine
                    />
                </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4 d-flex justify-content-end gap-2">
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          {editingProtocol ? 'Update' : 'Add'} Protocol
        </button>
      </div>
    </form>
  );
};

export default ProtocolForm;