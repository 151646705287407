import React, { useContext, useCallback } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './context/UserContext';
import Loader from './components/Loader';
import Protocols from './pages/Protocols';
import AddEditProtocol from './pages/AddEditProtocol';
import Hospitals from './pages/Hospitals';
import Login from './pages/Login';
import Users from './pages/Users';
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';
import ProtocolViewer from './pages/ProtocolViewer';
import Welcome from './pages/Welcome';
import Training from './pages/Training';
import EmekidHomePage from './pages/EmekidHomePage';
import Cases from './pages/Cases';
import AddEditCase from './pages/AddEditCase';
import CaseView from './pages/CaseView';
import Settings from './pages/Settings';
import TestTubeManagement from './pages/test-tubes/TestTubes';
import TablesPage from './pages/resusAdmin/TablesPage';

const ProtectedRoute = () => {
  const { authToken, isInitialized } = useContext(UserContext);

  if (!authToken) {
    return <Navigate replace to="/login" />;
  }

  if (!isInitialized) {
    return (
      <div style={{ position: 'relative', minHeight: '100dvh' }}>
        <Loader isLoading={true} />
      </div>
    );
  }

  return <Outlet />;
};

const AppRoutes = () => {
  const { user, hospitals, isInitialized, authToken } = useContext(UserContext);

  const isEmekidMember = useCallback(() => {
    if (!user || !hospitals || hospitals.length === 0) return false;
    if (user.isSuperAdmin) return false;

    return hospitals.some(
      (hospital) => hospital.nameEnglish.toLowerCase() === 'emekid'
    );
  }, [user, hospitals]);

  const RootRedirect = () => {
    if (!authToken) {
      return <Navigate replace to="/login" />;
    }

    if (!isInitialized) {
      return (
        <div style={{ position: 'relative', minHeight: '100dvh' }}>
          <Loader isLoading={true} />
        </div>
      );
    }

    return (
      <Navigate replace to={isEmekidMember() ? '/Emekid' : '/protocols'} />
    );
  };

  if (!authToken) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    );
  }

  if (!isInitialized) {
    return (
      <div style={{ position: 'relative', minHeight: '100dvh' }}>
        <Loader isLoading={true} />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/welcome" element={<Welcome />} />

        <Route path="/Emekid" element={<EmekidHomePage />} />

        <Route path="/protocols" element={<Protocols />} />
        <Route path="/protocols/new" element={<AddEditProtocol />} />
        <Route path="/protocols/:id/view" element={<ProtocolViewer />} />
        <Route path="/protocols/:id/edit" element={<AddEditProtocol />} />

        <Route path="/hospitals" element={<Hospitals />} />
        <Route path="/settings" element={<Settings />} />        
        <Route path="/test-tubes" element={<TestTubeManagement />} />        
        <Route path="/resusAdmin" element={<TablesPage />} />        
        <Route path="/profile" element={<EditUser />} />

        <Route path="/users" element={<Users />} />
        <Route path="/users/new" element={<AddUser />} />
        <Route path="/users/:id" element={<EditUser />} />

        <Route path="/training" element={<Training />} />

        <Route path="/cases" element={<Cases />} />
        <Route path="/cases/new" element={<AddEditCase />} />
        <Route path="/cases/:id/view" element={<CaseView />} />
        <Route path="/cases/:id/edit" element={<AddEditCase />} />
      </Route>

      <Route path="/" element={<RootRedirect />} />
      <Route path="*" element={<Navigate replace to="/protocols" />} />
    </Routes>
  );
};

export default AppRoutes;
