import React, { useState, useEffect } from 'react';
import { 
  TextField, Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, Chip, FormControl,
  InputLabel, Select, MenuItem
} from '@mui/material';
import LoadingSubmitButton from '../../components/LoadingSubmitButton';

// Color mapping for Hebrew color names
const colorMap = {
  'אדום': 'red',
  'כחול': 'blue',
  'ירוק': 'green',
  'צהוב': 'yellow',
  'סגול': 'purple',
  'שחור': 'black',
  'כתום': 'orange',
  'ורוד': 'pink',
  'אפור': 'gray',
  'לבן': 'white',
  'חום': 'brown',
  'טורקיז': 'turquoise'
};

// Detect color in a string
const detectColor = (text: string): string | null => {
  if (!text) return null;
  
  for (const [hebrewColor, englishValue] of Object.entries(colorMap)) {
    if (text.includes(hebrewColor)) {
      return englishValue;
    }
  }
  return null;
};

interface TestTubeData {
  mainTitle: string;
  shortName: string;
  performingLaboratory: string;
  sampleSource: string;
  collectionTool: string;
  color: string;
  collectionInstructions: string;
  clarificationsAndExplanations: string;
  notes: string;
  keywords: string[];
}

interface AddEditTestTubeProps {
  open: boolean;
  onClose: () => void;
  testTube: TestTubeData;
  editMode: boolean;
  onSave: (testTube: TestTubeData) => void;
  isSubmitting: boolean;
}

const AddEditTestTube: React.FC<AddEditTestTubeProps> = ({
  open,
  onClose,
  testTube,
  editMode,
  onSave,
  isSubmitting
}) => {
  const [currentTestTube, setCurrentTestTube] = useState<TestTubeData>(testTube);
  const [keyword, setKeyword] = useState<string>('');
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    if (testTube) {
      setCurrentTestTube(testTube);
    }
  }, [testTube]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setCurrentTestTube({ ...currentTestTube, [name]: value });
    
    // Clear validation error if the required field is filled
    if (name === 'mainTitle' && value.trim() && validationError) {
      setValidationError(null);
    }
  };

  const handleCollectionToolBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const detectedColor = detectColor(value);
    if (detectedColor && !currentTestTube.color) {
      setCurrentTestTube({ ...currentTestTube, color: detectedColor });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && keyword.trim()) {
      e.preventDefault();
      handleAddKeyword();
    }
  };
  
  const handleAddKeyword = () => {
    if (keyword.trim() && !currentTestTube.keywords.includes(keyword.trim())) {
      setCurrentTestTube({
        ...currentTestTube,
        keywords: [...currentTestTube.keywords, keyword.trim()]
      });
      setKeyword('');
    }
  };

  const handleDeleteKeyword = (keywordToDelete: string) => {
    setCurrentTestTube({
      ...currentTestTube,
      keywords: currentTestTube.keywords.filter((k) => k !== keywordToDelete)
    });
  };

  const handleSave = () => {
    // Validate required field
    if (!currentTestTube.mainTitle.trim()) {
      setValidationError('כותרת ראשית הינה שדה חובה');
      return;
    }

    onSave(currentTestTube);
  };

  const handleColorChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentTestTube({ ...currentTestTube, color: e.target.value as string });
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      dir="rtl"
    >
      <DialogTitle>
        {editMode ? 'עריכת מבחנה' : 'הוספת מבחנה חדשה'}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          required
          fullWidth
          label="כותרת ראשית"
          name="mainTitle"
          value={currentTestTube.mainTitle}
          onChange={handleInputChange}
          helperText={validationError || "שדה חובה"}
          error={!!validationError}
        />
        <TextField
          margin="dense"
          fullWidth
          label="שם מקוצר"
          name="shortName"
          value={currentTestTube.shortName}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          fullWidth
          label="מעבדה מבצעת"
          name="performingLaboratory"
          value={currentTestTube.performingLaboratory}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          fullWidth
          label="מקור דגימה"
          name="sampleSource"
          value={currentTestTube.sampleSource}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          fullWidth
          label="כלי איסוף"
          name="collectionTool"
          value={currentTestTube.collectionTool}
          onChange={handleInputChange}
          onBlur={handleCollectionToolBlur}
        />
        <FormControl 
          fullWidth 
          margin="dense"
        >
          <InputLabel>צבע מבחנה</InputLabel>
          <Select
            value={currentTestTube.color}
            onChange={handleColorChange}
            name="color"
            renderValue={(selected) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: selected as string,
                  marginLeft: '8px'
                }} />
                {Object.entries(colorMap).find(([heb, eng]) => eng === selected)?.[0] || selected}
              </div>
            )}
          >
            <MenuItem value="">
              <em>בחר צבע</em>
            </MenuItem>
            {Object.entries(colorMap).map(([hebrewName, englishValue]) => (
              <MenuItem key={englishValue} value={englishValue}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: englishValue,
                    marginLeft: '8px'
                  }} />
                  {hebrewName}
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          fullWidth
          label="הנחיות לקיחה"
          name="collectionInstructions"
          value={currentTestTube.collectionInstructions}
          onChange={handleInputChange}
          multiline
          rows={2}
        />
        <TextField
          margin="dense"
          fullWidth
          label="הבהרות והסברים"
          name="clarificationsAndExplanations"
          value={currentTestTube.clarificationsAndExplanations}
          onChange={handleInputChange}
          multiline
          rows={3}
        />
        <TextField
          margin="dense"
          fullWidth
          label="הערות"
          name="notes"
          value={currentTestTube.notes}
          onChange={handleInputChange}
          multiline
          rows={2}
        />
        
        {/* Keywords Section */}
        <div style={{ marginTop: '16px', marginBottom: '8px' }}>
          מילות מפתח
        </div>
        <TextField
          fullWidth
          label="הוסף מילת מפתח"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyPress={handleKeyPress}
          onBlur={handleAddKeyword}
          placeholder="הקלד מילת מפתח ולחץ אנטר להוספה"
          margin="dense"
        />
        <div className="tags-container" style={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
          {currentTestTube.keywords.map((kw, index) => (
            <Chip
              key={index}
              label={kw}
              onDelete={() => handleDeleteKeyword(kw)}
              sx={{
                backgroundColor: '#1FB5A3',
                color: 'white',
                margin: '0 0 8px 8px',
                '& .MuiChip-deleteIcon': {
                  color: 'white',
                  '&:hover': {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                }
              }}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: '16px 24px' }}>
        <Button 
          onClick={onClose} 
          style={{ marginLeft: '16px' }}
        >
          ביטול
        </Button>
        <LoadingSubmitButton
          isLoading={isSubmitting}
          text={editMode ? "עדכן" : "שמור"}
          loadingText={editMode ? "מעדכן..." : "שומר..."}
          type="button"
          onClick={handleSave}
          style={{
            backgroundColor: '#103C6E',
            color: 'white',
            minWidth: '120px'
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddEditTestTube;