import React, { useState } from 'react';
import { SimpleDrip, CasedDrip, Drip, DoseUnit, CalcType, WeightPredicate, DripCase, DripDetails } from './types';

interface DoseRangeProps {
  value?: string;
  onChange: (value: string) => void;
}

const DoseRangeInput: React.FC<DoseRangeProps> = ({ value, onChange }) => {
  const [min, setMin] = useState(value ? value.split(' ')[0].split('-')[0] : '');
  const [max, setMax] = useState(value ? value.split(' ')[0].split('-')[1] : '');
  const [units, setUnits] = useState(value ? value.split(' ')[1]?.trim() : '');

  const handleChange = () => {
    console.log ('***', {min, max, units});
    if (min && max && units) {
      onChange(`${min}-${max} ${units}`);
    }
  };

  return (
    <div className="row g-3">
      <div className="col-md-4">
        <label className="form-label">Min</label>
        <input
          type="number"
          className="form-control"
          value={min}
          onChange={(e) => {
            setMin(e.target.value);
            handleChange();
          }}
        />
      </div>
      <div className="col-md-4">
        <label className="form-label">Max</label>
        <input
          type="number"
          className="form-control"
          value={max}
          onChange={(e) => {
            setMax(e.target.value);
            handleChange();
          }}
        />
      </div>
      <div className="col-md-4">
        <label className="form-label">Units</label>
        <input
          type="text"
          className="form-control"
          value={units}
          onChange={(e) => {
            setUnits(e.target.value);
            handleChange();
          }}
        />
      </div>
    </div>
  );
};


interface DripMedicineFormProps {
  onSubmit: (drip: Drip) => void;
  onCancel: () => void;
  editingDrip?: Drip | null;
}

const DripMedicineForm: React.FC<DripMedicineFormProps> = ({ onSubmit, onCancel, editingDrip }) => {
    // Determine initial time unit based on existing drip data
  const determineInitialTimeUnit = () => {
    const simpleDrip = editingDrip as SimpleDrip;
    if (simpleDrip?.dose_per_kg_per_hour) return 'hour';
    return 'minute';
  };

  const [isCasedDrip, setIsCasedDrip] = useState<boolean>(
    editingDrip ? 'cases' in editingDrip : false
  );
  
  const [cases, setCases] = useState<DripCase[]>(
    (editingDrip && 'cases' in editingDrip) ? editingDrip.cases : []
  );

  const [dosePerKgTimeUnit, setDosePerKgTimeUnit] = useState<'minute' | 'hour'>(
    determineInitialTimeUnit()
  );

  const [dosePerKg, setDosePerKg] = useState<number>(() => {
  // Initial value logic similar to your existing function
  if (editingDrip) {
    const drip = editingDrip as SimpleDrip;
    return dosePerKgTimeUnit === 'minute' 
      ? drip.dose_per_kg_per_min || 0 
      : drip.dose_per_kg_per_hour || 0;
  }
  return 0;
    });

  const [calcType, setCalcType] = useState<CalcType>(
    (editingDrip as SimpleDrip)?.calc_type || CalcType.DILUTION_INSTRUCTIONS
  );

  const addCase = () => {
    const newCase: DripCase = {
      predicate: {
        type: 'weight',
        minWeight: 0,
        maxWeight: 0
      },
      details: {
        dose_unit: DoseUnit.MCG,
        calc_type: CalcType.DILUTION_INSTRUCTIONS,
        default_dilution_volume_ml: 50,
        target_volume_ml_per_hour: 0,
      }
    };
    setCases([...cases, newCase]);
  };

  // const updateCase = (index: number, field: keyof DripDetails | keyof WeightPredicate, value: any) => {
  //   const updated = [...cases];
  //   if (field in updated[index].details) {
  //     updated[index] = {
  //       ...updated[index],
  //       details: { ...updated[index].details, [field]: value }
  //     };
  //   } else {
  //     updated[index] = {
  //       ...updated[index],
  //       predicate: { ...updated[index].predicate, [field]: value }
  //     };
  //   }
  //   setCases(updated);
  // };
  const updateCase = (index: number, field: string, value: any) => {
    const updated = [...cases];
    const fields = [
      'dose_unit', 'calc_type', 'default_dilution_volume_ml', 
      'target_volume_ml_per_hour', 'minWeight', 'maxWeight', 'dose_per_kg_per_min', 'dose_per_kg_per_hour'
    ];

    if (fields.includes(field)) {
      if (['minWeight', 'maxWeight'].includes(field)) {
        updated[index] = {
          ...updated[index],
          predicate: { ...updated[index].predicate, [field]: value }
        };
      } else {
        updated[index] = {
          ...updated[index],
          details: { ...updated[index].details, [field]: value }
        };

      if (dosePerKgTimeUnit === 'minute'){
        updated[index] = {
          ...updated[index],
          details: { ...updated[index].details, dose_per_kg_per_min: dosePerKg }
        };
        }else if (dosePerKgTimeUnit === 'hour') {
          updated[index] = {
            ...updated[index],
            details: { ...updated[index].details, dose_per_kg_per_hour: dosePerKg }
          };
        }
      }
      setCases(updated);
    }
  };
  
  const removeCase = (index: number) => {
    setCases(cases.filter((_, i) => i !== index));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const dosePerKg = parseFloat(formData.get('dose_per_kg') as string);

    if (isCasedDrip) {
      const casedDrip: CasedDrip = {
        id: editingDrip?.id || `${formData.get('name')}_${Date.now()}`,
        name: formData.get('name') as string,
        cases: cases,
        // dose_per_kg_per_min: dosePerKgTimeUnit === 'minute' ? dosePerKg : undefined,
        // dose_per_kg_per_hour: dosePerKgTimeUnit === 'hour' ? dosePerKg : undefined,
        // dose_unit: formData.get('dose_unit') as string,
        // allowed_dose_range: formData.get('allowed_dose_range') as string,
        // calc_type: calcType
      };
      onSubmit(casedDrip);
    } else {
      const simpleDrip: SimpleDrip = {
        id: editingDrip?.id || `${formData.get('name')}_${Date.now()}`,
        name: formData.get('name') as string,
        dose_per_kg_per_min: dosePerKgTimeUnit === 'minute' ? dosePerKg : undefined,
        dose_per_kg_per_hour: dosePerKgTimeUnit === 'hour' ? dosePerKg : undefined,
        dose_unit: formData.get('dose_unit') as string,
        allowed_dose_range: formData.get('allowed_dose_range') as string,
        calc_type: calcType,
        default_dilution_volume_ml: calcType === CalcType.DILUTION_INSTRUCTIONS
          ? parseInt(formData.get('dilution_volume') as string)
          : undefined,
        target_volume_ml_per_hour: calcType === CalcType.DILUTION_INSTRUCTIONS
          ? parseFloat(formData.get('target_volume_ml_per_hour') as string)
          : undefined,
        existing_dilution_concentration: calcType === CalcType.INFUSION_SPEED
          ? formData.get('existing_dilution_concentration') as string
          : undefined,
        existing_dilution_concentration_dose_unit: calcType === CalcType.INFUSION_SPEED
          ? formData.get('existing_dilution_concentration_dose_unit') as string
          : undefined,
        max_dose_per_hour: calcType === CalcType.INFUSION_SPEED
          ? parseFloat(formData.get('max_dose_per_hour') as string)
          : undefined      };
      onSubmit(simpleDrip);
    }
  };

  // // Get initial dose per kg value from existing drip
  // const getInitialDosePerKgValue = () => {
  //   let Drip = editingDrip as SimpleDrip;
  //   if (dosePerKgTimeUnit === 'minute') return Drip?.dose_per_kg_per_min || 0;
  //   return Drip?.dose_per_kg_per_hour || 0;
  // };


  return (
    <form onSubmit={handleSubmit}>
      {/* Basic Details */}
      <div className="row g-3">
        <div className="col-md-6">
          <label className="form-label">Name</label>
          <input
            name="name"
            className="form-control"
            required
            defaultValue={editingDrip?.name || ''}
          />
        </div>
        
        <div className="col-md-6">
          <label className="form-label">Drip Type</label>
          <select
            className="form-select"
            value={isCasedDrip ? 'cased' : 'simple'}
            onChange={(e) => setIsCasedDrip(e.target.value === 'cased')}
          >
            <option value="simple">Simple Drip</option>
            <option value="cased">Cased Drip</option>
          </select>
        </div>

        {/* Dose per kg time unit and value */}
          <div className="col-md-6">
            <label className="form-label">Dose per kg time unit</label>
            <select
              className="form-select"
              value={dosePerKgTimeUnit}
              onChange={(e) => setDosePerKgTimeUnit(e.target.value as 'minute' | 'hour')}
            >
              <option value="minute">per minute</option>
              <option value="hour">per hour</option>
            </select>
          </div>
          
          <div className="col-md-6">
            <label className="form-label">Dose per kg</label>
            <input
              name="dose_per_kg"
              type="number"
              step="0.01"
              className="form-control"
              value ={dosePerKg}
              // defaultValue={getInitialDosePerKgValue()}
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                setDosePerKg(value)
              }}
            />
          </div>


        {/* {!isCasedDrip && (
          <> */}
        {/* Dose Unit */}
          <div className="col-md-6">
            <label className="form-label">Dose Unit</label>
            <select
              name="dose_unit"
              className="form-select"
              defaultValue={(editingDrip as SimpleDrip)?.dose_unit || DoseUnit.MCG}
            >
              {Object.values(DoseUnit).map((unit) => (
                <option key={unit} value={unit}>{unit}</option>
              ))}
            </select>
          </div>

        {/* Allowed Dose Range */}
          <div className="col-md-12">
            <label className="form-label">Allowed Dose Range</label>
            <DoseRangeInput 
              value={(editingDrip as SimpleDrip)?.allowed_dose_range}
              onChange={(value) => {
                // This will set the value in the form data
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'allowed_dose_range';
                input.value = value;
                document.querySelector('form')?.appendChild(input);
              }}
            />
          </div>
          {/* Calculation Type */}
            <div className="col-md-6">
              <label className="form-label">Calculation Type</label>
              <select
                name="calc_type"
                className="form-select"
                // defaultValue={(editingDrip as SimpleDrip)?.calc_type || CalcType.DILUTION_INSTRUCTIONS}
                value={calcType}
                onChange={(e) => setCalcType(e.target.value as CalcType)}
              >
                {Object.values(CalcType).map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

          {/* Dilution Instructions Fields */}
           {calcType === CalcType.DILUTION_INSTRUCTIONS && (
             <>
                <div className="col-md-6">
                  <label className="form-label">Default Dilution Volume (ml)</label>
                  <input
                    name="dilution_volume"
                    type="number"
                    className="form-control"
                    defaultValue={(editingDrip as SimpleDrip)?.default_dilution_volume_ml || 50}
                  />
                </div>
              { (!isCasedDrip) && (
                <div className="col-md-6">
                  <label className="form-label">Target Volume (ml/hour)</label>
                  <input
                    name="target_volume_ml_per_hour"
                    type="number"
                    step="0.1"
                    className="form-control"
                    defaultValue={(editingDrip as SimpleDrip)?.target_volume_ml_per_hour || 0}
                  />
                </div>
              )}
              </>
            )}

          {/* Infusion Speed Fields */}
          {calcType === CalcType.INFUSION_SPEED && (
           <>
            <div className="col-md-6">
              <label className="form-label">Existing Dilution Concentration</label>
              <input
                name="existing_dilution_concentration"
                type="text"
                className="form-control"
                defaultValue={(editingDrip as SimpleDrip)?.existing_dilution_concentration || ''}
              />
            </div>

            <div className="col-md-6">
              <label className="form-label">Existing Dilution Concentration Dose Unit</label>
              <input
                name="existing_dilution_concentration_dose_unit"
                type="text"
                className="form-control"
                defaultValue={(editingDrip as SimpleDrip)?.existing_dilution_concentration_dose_unit || ''}
              />
            </div>

            <div className="col-md-6">
              <label className="form-label">Max Dose per hour</label>
              <input
                name="max_dose_per_hour"
                type="number"
                step="0.1"
                className="form-control"
                defaultValue={(editingDrip as SimpleDrip)?.max_dose_per_hour || 0}
              />
            </div>
           </>
          )}
          {/* </>
        )} */}
      </div>

      {/* Cases Section */}
      {isCasedDrip && (
        <div className="mt-4">
          <h4>Weight Cases</h4>
          {cases.map((caseItem, index) => (
            <div key={index} className="card mb-3">
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <label className="form-label">Min Weight (kg)</label>
                    <input
                      type="number"
                      className="form-control"
                      value={caseItem.predicate.minWeight || 0}
                      onChange={(e) => updateCase(index, 'minWeight', parseFloat(e.target.value))}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Max Weight (kg)</label>
                    <input
                      type="number"
                      className="form-control"
                      value={caseItem.predicate.maxWeight || 0}
                      onChange={(e) => updateCase(index, 'maxWeight', parseFloat(e.target.value))}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">Target Volume (ml/hour)</label>
                    <input
                      type="number"
                      className="form-control"
                      value={caseItem.details.target_volume_ml_per_hour}
                      onChange={(e) => updateCase(index, 'target_volume_ml_per_hour', parseFloat(e.target.value))}
                    />
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-danger mt-3"
                  onClick={() => removeCase(index)}
                >
                  Remove Case
                </button>
              </div>
            </div>
          ))}
          <button type="button" className="btn btn-secondary" onClick={addCase}>
            Add Case
          </button>
        </div>
      )}

      <div className="mt-4 d-flex justify-content-end gap-2">
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          {editingDrip ? 'Update' : 'Add'} Drip
        </button>
      </div>
    </form>
  );
};

export default DripMedicineForm;