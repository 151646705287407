import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import CommonLayout from '../components/CommonLayout';
import './EmekidHomePage.css';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
});

const categories = [
  { id: 'picu', name: 'טיפול נמרץ ילדים' },
  { id: 'pediatric-er', name: 'מלר"ד ילדים' },
  { id: 'nicu', name: 'פגיה וילודים' },
  { id: 'infectious', name: 'זיהומיות' },
  { id: 'nephrology', name: 'נפרולוגיה' },
  { id: 'neurology', name: 'נוירולוגיה' },
  { id: 'pulmonology', name: 'ריאות' },
  { id: 'gastro', name: 'גסטרואנטרולוגיה' },
  { id: 'dermatology', name: 'עור' },
  { id: 'psychiatry', name: 'פסיכיאטריה' },
  { id: 'metabolic', name: 'מטבוליות' },
  { id: 'endocrinology', name: 'אנדוקרינולוגיה' },
  { id: 'hematology', name: 'המטולוגיה' },
  { id: 'immunology', name: 'אימונולוגיה ואלרגיה' },
  { id: 'rheumatology', name: 'ראומטולוגיה' },
  { id: 'oncology', name: 'אונקולוגיה' },
  { id: 'cardiology', name: 'קרדיולוגיה' },
  { id: 'misc', name: 'שונות - טיפול בצנתר מרכזי BRUE' }
];

function EmekidHomePage() {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/protocols?tags=${category.name}`);
  };

  return (
    <CommonLayout>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div className="homepage-container">
            <div className="category-grid">
              {categories.map(category => (
                <div
                  key={category.id}
                  className="category-cube"
                  onClick={() => handleCategoryClick(category)}
                >
                  <span className="category-name">{category.name}</span>
                </div>
              ))}
            </div>
          </div>
        </ThemeProvider>
      </CacheProvider>
    </CommonLayout>
  );
}

export default EmekidHomePage;