import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import '../pages/Settings.css';

interface AppConfig {
  appName: string;
  configName: string;
  hospital: string;
  createdAt?: string;
  updatedAt?: string;
}

interface AppData {
  [appName: string]: {
    [configName: string]: {
      [hospital: string]: AppConfig | null;
    }
  }
}

const AppConfigSettings: React.FC = () => {
  const { hospitals, user } = useContext(UserContext);
  const [appData, setAppData] = useState<AppData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [newAppName, setNewAppName] = useState<string>('');
  const [newConfigName, setNewConfigName] = useState<string>('');
  const [selectedApp, setSelectedApp] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ appName: string, configName: string, hospital: string } | null>(null);

  // Fetch all configurations
  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      setLoading(true);
      const response = await API.get('/api/configs');
      
      // Transform the data to our hierarchical format
      const transformedData: AppData = {};
      
      response.data.data.forEach((config: AppConfig) => {
        // Initialize app if not exists
        if (!transformedData[config.appName]) {
          transformedData[config.appName] = {};
        }
        
        // Initialize config if not exists
        if (!transformedData[config.appName][config.configName]) {
          transformedData[config.appName][config.configName] = {};
        }
        
        // Add hospital configuration
        transformedData[config.appName][config.configName][config.hospital] = config;
      });
      
      setAppData(transformedData);
    } catch (err) {
      console.error('Error fetching configurations:', err);
      setError('שגיאה בטעינת הנתונים');
    } finally {
      setLoading(false);
    }
  };

  // Handle file import
  const handleImport = async (appName: string, configName: string, hospital: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const fileContent = JSON.parse(e.target?.result as string);
          
          // Make API call to import
          await API.post('/api/configs/import', {
            appName,
            configName,
            hospital,
            data: fileContent
          });
          
          // Show success message
          setSuccessMessage(`הקובץ יובא בהצלחה ל-${appName}/${configName}/${hospital}`);
          setTimeout(() => setSuccessMessage(''), 3000);
          
          // Refresh the list
          fetchConfigs();
        } catch (err) {
          console.error('Error importing file:', err);
          setError('שגיאה בייבוא הקובץ');
          setTimeout(() => setError(''), 3000);
        }
      };
      reader.readAsText(file);
    } catch (err) {
      console.error('Error reading file:', err);
      setError('שגיאה בקריאת הקובץ');
      setTimeout(() => setError(''), 3000);
    }
  };

  // Handle file export
  const handleExport = async (appName: string, configName: string, hospital: string) => {
    try {
      const response = await API.get(`/api/configs/export/${appName}/${configName}/${hospital}`);
      
      // Create downloadable file
      const fileContent = JSON.stringify(response.data.data, null, 2);
      const blob = new Blob([fileContent], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      
      // Create download link
      const a = document.createElement('a');
      a.href = url;
      a.download = `${appName}-${configName}-${hospital}.json`;
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      
      setSuccessMessage('הקובץ יוצא בהצלחה');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      console.error('Error exporting file:', err);
      setError('שגיאה בייצוא הקובץ');
      setTimeout(() => setError(''), 3000);
    }
  };

  // Handle deleting config
  const handleDelete = async (appName: string, configName: string, hospital: string) => {
    setDeleteConfirmation({ appName, configName, hospital });
  };

  // Confirm deletion
  const confirmDelete = async () => {
    if (!deleteConfirmation) return;
    
    const { appName, configName, hospital } = deleteConfirmation;
    try {
      await API.delete(`/api/configs/${appName}/${configName}/${hospital}`);
      
      setSuccessMessage(`התצורה נמחקה בהצלחה: ${appName}/${configName}/${hospital}`);
      setTimeout(() => setSuccessMessage(''), 3000);
      
      // Update local state to remove the deleted config
      const newAppData = { ...appData };
      if (newAppData[appName] && 
          newAppData[appName][configName] && 
          newAppData[appName][configName][hospital]) {
        delete newAppData[appName][configName][hospital];
        
        // Clean up empty objects
        if (Object.keys(newAppData[appName][configName]).length === 0) {
          delete newAppData[appName][configName];
        }
        if (Object.keys(newAppData[appName]).length === 0) {
          delete newAppData[appName];
        }
        
        setAppData(newAppData);
      }
    } catch (err) {
      console.error('Error deleting configuration:', err);
      setError('שגיאה במחיקת התצורה');
      setTimeout(() => setError(''), 3000);
    } finally {
      setDeleteConfirmation(null);
    }
  };

  // Cancel deletion
  const cancelDelete = () => {
    setDeleteConfirmation(null);
  };

  // Handle adding new app
  const handleAddApp = () => {
    if (!newAppName.trim()) {
      setError('שם האפליקציה לא יכול להיות ריק');
      setTimeout(() => setError(''), 3000);
      return;
    }
    
    // Check if app already exists
    if (appData[newAppName]) {
      setError('אפליקציה בשם זה כבר קיימת');
      setTimeout(() => setError(''), 3000);
      return;
    }
    
    // Add new app to the state
    setAppData(prev => ({
      ...prev,
      [newAppName]: {}
    }));
    
    setNewAppName('');
    setSelectedApp(newAppName);
    setSuccessMessage('האפליקציה נוספה בהצלחה');
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  // Handle adding new config to selected app
  const handleAddConfig = () => {
    if (!selectedApp) {
      setError('יש לבחור אפליקציה תחילה');
      setTimeout(() => setError(''), 3000);
      return;
    }
    
    if (!newConfigName.trim()) {
      setError('שם התצורה לא יכול להיות ריק');
      setTimeout(() => setError(''), 3000);
      return;
    }
    
    // Check if config already exists
    if (appData[selectedApp][newConfigName]) {
      setError('תצורה בשם זה כבר קיימת באפליקציה זו');
      setTimeout(() => setError(''), 3000);
      return;
    }
    
    // Add new config to the selected app
    setAppData(prev => ({
      ...prev,
      [selectedApp]: {
        ...prev[selectedApp],
        [newConfigName]: {}
      }
    }));
    
    setNewConfigName('');
    setSuccessMessage('התצורה נוספה בהצלחה');
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  // Show available hospitals with the default "apps" added
  const availableHospitals = [
    { name: 'apps' },
    ...(hospitals.map((hospital) => ({name: hospital.nameEnglish})) || [])
  ];

  return (
    <div className="app-config-settings">
      {loading ? (
        <div className="loading">טוען נתונים...</div>
      ) : (
        <>
          {error && <div className="error-message">{error}</div>}
          {successMessage && <div className="success-message">{successMessage}</div>}
          
          <section className="settings-section">
            <h2 className="section-title">הגדרות אפליקציות</h2>
            
            <div className="add-new-container">
              <div className="add-app-form">
                <input
                  type="text"
                  value={newAppName}
                  onChange={(e) => setNewAppName(e.target.value)}
                  placeholder="שם אפליקציה חדשה"
                  className="input-field"
                />
                <button 
                  className="button-primary"
                  onClick={handleAddApp}
                >
                  הוסף אפליקציה
                </button>
              </div>
              
              {Object.keys(appData).length > 0 && (
                <div className="add-config-form">
                  <select
                    value={selectedApp}
                    onChange={(e) => setSelectedApp(e.target.value)}
                    className="select-field"
                  >
                    <option value="">בחר אפליקציה</option>
                    {Object.keys(appData).map(app => (
                      <option key={app} value={app}>{app}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    value={newConfigName}
                    onChange={(e) => setNewConfigName(e.target.value)}
                    placeholder="שם תצורה חדשה"
                    className="input-field"
                    disabled={!selectedApp}
                  />
                  <button 
                    className="button-primary"
                    onClick={handleAddConfig}
                    disabled={!selectedApp}
                  >
                    הוסף תצורה
                  </button>
                </div>
              )}
            </div>
            
            <div className="apps-list">
              {Object.keys(appData).length === 0 ? (
                <div className="no-data">לא נמצאו אפליקציות מוגדרות</div>
              ) : (
                Object.entries(appData).map(([appName, configs]) => (
                  <div key={appName} className="app-item">
                    <h3 className="app-name">{appName}</h3>
                    
                    <div className="configs-list">
                      {Object.keys(configs).length === 0 ? (
                        <div className="no-data">לא נמצאו תצורות לאפליקציה זו</div>
                      ) : (
                        Object.entries(configs).map(([configName, hospitals]) => (
                          <div key={configName} className="config-item">
                            <h4 className="config-name">{configName}</h4>
                            
                            <table className="hospitals-table">
                              <thead>
                                <tr>
                                  <th>בית חולים</th>
                                  <th>סטטוס</th>
                                  <th>פעולות</th>
                                </tr>
                              </thead>
                              <tbody>
                                {availableHospitals.map(hospital => {
                                  const hospitalExists = hospitals[hospital.name];
                                  
                                  return (
                                    <tr key={hospital.name}>
                                      <td>{hospital.name}</td>
                                      <td>
                                        {hospitalExists ? (
                                          <span className="status-exists">קיים</span>
                                        ) : (
                                          <span className="status-missing">לא קיים</span>
                                        )}
                                      </td>
                                      <td className="actions-cell">
                                        <div className="file-import-container">
                                          <label htmlFor={`import-${appName}-${configName}-${hospital.name}`} className="button-secondary">
                                            ייבוא
                                          </label>
                                          <input
                                            id={`import-${appName}-${configName}-${hospital.name}`}
                                            type="file"
                                            accept=".json"
                                            onChange={(e) => handleImport(appName, configName, hospital.name, e)}
                                            className="file-input"
                                          />
                                        </div>
                                        
                                        {hospitalExists && (
                                          <>
                                            <button
                                              className="button-secondary"
                                              onClick={() => handleExport(appName, configName, hospital.name)}
                                            >
                                              ייצוא
                                            </button>
                                            <button
                                              className="button-danger"
                                              onClick={() => handleDelete(appName, configName, hospital.name)}
                                            >
                                              מחק
                                            </button>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </section>
          
          {/* Delete confirmation modal */}
          {deleteConfirmation && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h3 className="modal-title">אישור מחיקה</h3>
                <p>האם אתה בטוח שברצונך למחוק את התצורה הבאה?</p>
                <p className="delete-details">
                  <strong>אפליקציה:</strong> {deleteConfirmation.appName}<br />
                  <strong>תצורה:</strong> {deleteConfirmation.configName}<br />
                  <strong>בית חולים:</strong> {availableHospitals.find(h => h.name === deleteConfirmation.hospital)?.nameHebrew || deleteConfirmation.hospital}
                </p>
                <p className="delete-warning">פעולה זו אינה ניתנת לביטול!</p>
                <div className="modal-actions">
                  <button 
                    className="button-danger" 
                    onClick={confirmDelete}
                  >
                    כן, מחק
                  </button>
                  <button 
                    className="button-secondary" 
                    onClick={cancelDelete}
                  >
                    ביטול
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AppConfigSettings;