import React, { useState, useEffect } from "react";
import { 
  ChevronDown, 
  Plus, 
  FileText, 
  Book, 
  Trash2, 
  BookPlus, 
  BookX, 
  FileUp, 
  Building, 
  ExternalLink,
  ChevronUp,
  Pencil
} from "lucide-react";
import API from "../../config/axiosConfig";
import { 
  useHospitalsWithEditorPermission,
  useTrainingTopics,
  useTrainingTests
} from "./hooks";
import { 
  CustomSelect,
  ImageUploadComponent,
  QuestionForm
} from "./components";
import {
  clearAllImageStates,
  removeQuestionImage,
  removeExplanationImage,
  getClientViewUrl
} from "./utils/imageHelpers";
import "./styles/Training.css";

// Main Training Component
const Training = () => {
  const [selectedHospitalId, setSelectedHospitalId] = useState("");
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [selectedTopicName, setSelectedTopicName] = useState("");
  const [selectedTestId, setSelectedTestId] = useState("");
  
  const { 
    hospitals, 
    loading: hospitalsLoading, 
  } = useHospitalsWithEditorPermission();
  
  const {
    topics,
    loading: topicsLoading,
    refreshTopics
  } = useTrainingTopics(selectedHospitalId);
  
  const {
    tests,
    loading: testsLoading,
    error: testsError,
    topicData,
    setTests
  } = useTrainingTests(selectedTopicId, selectedHospitalId);
  
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showNewTestInput, setShowNewTestInput] = useState(false);
  const [newTestTitle, setNewTestTitle] = useState("");
  const [showNewTopicInput, setShowNewTopicInput] = useState(false);
  const [newTopicName, setNewTopicName] = useState("");
  const [newTopicHospitalId, setNewTopicHospitalId] = useState("");
  const [isEditingTestName, setIsEditingTestName] = useState(false);
  const [editedTestName, setEditedTestName] = useState("");
  const [isSelectingTest, setIsSelectingTest] = useState(false);

  // Image handling states - improved to use objects keyed by question index
  const [questionImages, setQuestionImages] = useState({});
  const [explanationImages, setExplanationImages] = useState({});
  const [questionImagePreviews, setQuestionImagePreviews] = useState({});
  const [explanationImagePreviews, setExplanationImagePreviews] = useState({});

  // Auto-select hospital if there's only one
  useEffect(() => {
    if (!hospitalsLoading && hospitals.length === 1 && !selectedHospitalId) {
      setSelectedHospitalId(hospitals[0]._id);
    }
  }, [hospitals, hospitalsLoading, selectedHospitalId]);

  useEffect(() => {
    if (topicData?.hospital?.id && !selectedHospitalId) {
      setSelectedHospitalId(topicData.hospital.id);
    }
  }, [topicData, selectedHospitalId]);

  // Handle question image selection - improved to be question-specific
  const handleQuestionImageChange = (questionIndex, e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      
      // Store the file for upload
      setQuestionImages(prev => ({
        ...prev,
        [questionIndex]: file
      }));
      
      // Create a preview URL
      const previewUrl = URL.createObjectURL(file);
      setQuestionImagePreviews(prev => ({
        ...prev,
        [questionIndex]: previewUrl
      }));
    }
  };

  // Handle explanation image selection - improved to be question-specific
  const handleExplanationImageChange = (questionIndex, e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      
      // Store the file for upload
      setExplanationImages(prev => ({
        ...prev,
        [questionIndex]: file
      }));
      
      // Create a preview URL
      const previewUrl = URL.createObjectURL(file);
      setExplanationImagePreviews(prev => ({
        ...prev,
        [questionIndex]: previewUrl
      }));
    }
  };

  // Handle clearing all image states
  const handleClearAllImageStates = () => {
    clearAllImageStates(
      questionImagePreviews,
      explanationImagePreviews,
      setQuestionImages,
      setExplanationImages,
      setQuestionImagePreviews,
      setExplanationImagePreviews
    );
  };

  // Handle removing question image
  const handleRemoveQuestionImage = (questionIndex) => {
    removeQuestionImage(
      questionIndex,
      questionImagePreviews,
      setQuestionImages,
      setQuestionImagePreviews,
      selectedTestId,
      tests,
      setTests
    );
  };

  // Handle removing explanation image
  const handleRemoveExplanationImage = (questionIndex) => {
    removeExplanationImage(
      questionIndex,
      explanationImagePreviews,
      setExplanationImages,
      setExplanationImagePreviews,
      selectedTestId,
      tests,
      setTests
    );
  };

  const handleHospitalSelect = (hospitalId) => {
    setSelectedHospitalId(hospitalId);
    setSelectedTopicId("");
    setSelectedTestId("");
  };

  const handleEditTestName = () => {
    const selectedTest = tests.find((test) => test.id === selectedTestId);
    if (!selectedTest) return;
    
    setEditedTestName(selectedTest.title || selectedTest.id);
    setIsEditingTestName(true);
  };

  const handleSaveTestName = async () => {
    if (!selectedTestId || !selectedTopicId || !selectedHospitalId || !editedTestName.trim()) {
      alert("Please provide a valid test name");
      return;
    }

    try {
      const selectedTest = tests.find((test) => test.id === selectedTestId);
      if (!selectedTest) {
        throw new Error("Selected test not found");
      }

      const updateData = {
        title: editedTestName.trim(),
        questions: selectedTest.questions.map((q) => ({
          ...q,
          correct: q.correct,
        })),
      };

      await API.put(
        `/api/trainings/training-topic/${encodeURIComponent(
          selectedTopicId
        )}/hospital/${encodeURIComponent(
          selectedHospitalId
        )}/test/${encodeURIComponent(selectedTestId)}`,
        updateData
      );

      const updatedTests = tests.map((test) => {
        if (test.id === selectedTestId) {
          return {
            ...test,
            title: editedTestName.trim()
          };
        }
        return test;
      });
      
      setTests(updatedTests);
      setIsEditingTestName(false);
      
      alert("Test name updated successfully!");
    } catch (error) {
      alert(
        "Failed to update test name: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleCancelEditTestName = () => {
    setIsEditingTestName(false);
    setEditedTestName("");
  };

  const handleTopicSelect = (topicId) => {
    if (!selectedHospitalId) {
      alert("Please select a hospital first");
      return;
    }
    
    if (!topicId) {
      return;
    }
    
    setSelectedTopicId(topicId);
    
    const selectedTopic = topics.find((topic) => topic.id === topicId);
    
    if (selectedTopic) {
      setSelectedTopicName(selectedTopic.name);
    } else {
      setSelectedTopicName("");
    }
    
    setSelectedTestId("");
    
    // Clear all image states
    handleClearAllImageStates();
  };

  const handleTestSelect = (testId) => {
    setSelectedTestId(testId);
    
    // Clear all image states first
    handleClearAllImageStates();
    
    // Check if the selected test has images and set previews
    const selectedTest = tests.find((test) => test.id === testId);
    
    if (selectedTest && selectedTest.questions && selectedTest.questions.length > 0) {
      // Set previews for each question's images
      selectedTest.questions.forEach((question, index) => {
        // Check for question images
        if (question.questionImage && question.questionImage.url) {
          setQuestionImagePreviews(prev => ({
            ...prev,
            [index]: question.questionImage.url
          }));
        }
        
        // Check for explanation images
        if (question.explanationImage && question.explanationImage.url) {
          setExplanationImagePreviews(prev => ({
            ...prev,
            [index]: question.explanationImage.url
          }));
        }
      });
    }
  };

  // Handle moving a test up in order
  const handleMoveTestUp = async (testId, e) => {
    if (e) {
      e.stopPropagation();
    }
    
    if (!selectedTopicId || !selectedHospitalId) {
      alert("Please select a topic and hospital first");
      return;
    }
  
    const orderedTests = getTests();
    const testIndex = orderedTests.findIndex(test => test.id === testId);
    if (testIndex <= 0) return;
  
    const currentTest = orderedTests[testIndex];
    const aboveTest = orderedTests[testIndex - 1];
  
    const updatedOrder = orderedTests.map(test => {
      if (test.id === currentTest.id) {
        return { id: test.id, order: aboveTest.order };
      } else if (test.id === aboveTest.id) {
        return { id: test.id, order: currentTest.order };
      }
      return { id: test.id, order: test.order };
    });
  
    try {
      await updateTestsOrder(updatedOrder);
    } catch (error) {
      alert(`Failed to update test order: ${error.message}`);
    }
  };

  // Handle moving a test down in order
  const handleMoveTestDown = async (testId, e) => {
    if (e) {
      e.stopPropagation();
    }
    
    if (!selectedTopicId || !selectedHospitalId) {
      alert("Please select a topic and hospital first");
      return;
    }
  
    const orderedTests = getTests();
    const testIndex = orderedTests.findIndex(test => test.id === testId);
    if (testIndex < 0 || testIndex >= orderedTests.length - 1) return;
  
    const currentTest = orderedTests[testIndex];
    const belowTest = orderedTests[testIndex + 1];
  
    const updatedOrder = orderedTests.map(test => {
      if (test.id === currentTest.id) {
        return { id: test.id, order: belowTest.order };
      } else if (test.id === belowTest.id) {
        return { id: test.id, order: currentTest.order };
      }
      return { id: test.id, order: test.order };
    });
  
    try {
      await updateTestsOrder(updatedOrder);
    } catch (error) {
      alert(`Failed to update test order: ${error.message}`);
    }
  };

  // Update test order via API
  const updateTestsOrder = async (orderData) => {
    try {
      const endpoint = `/api/trainings/training-topic/${encodeURIComponent(
        selectedTopicId
      )}/hospital/${encodeURIComponent(
        selectedHospitalId
      )}/update-order`;
  
      await API.put(endpoint, orderData);
  
      const updatedTests = [...tests].map(test => {
        const orderInfo = orderData.find(o => o.id === test.id);
        if (orderInfo) {
          return { ...test, order: orderInfo.order };
        }
        return test;
      });
  
      setTests(updatedTests);
    } catch (error) {
      console.error("Error updating test order:", error);
      throw error;
    }
  };

  const handleCreateNewTest = async () => {
    if (!selectedTopicId || !selectedHospitalId || !newTestTitle) {
      alert("Please fill in all required fields and ensure hospital and topic are selected");
      return;
    }

    try {
      const testId = newTestTitle.trim().replace(/\s+/g, "");
      const emptyAnswers = Array(4).fill("");
      const nextOrder = tests.length > 0 
        ? Math.max(...tests.map(test => test.order || 0)) + 1 
        : 0;

      // Create FormData for file upload
      const formData = new FormData();
      formData.append('id', testId);
      formData.append('title', newTestTitle);
      formData.append('order', nextOrder);
      
      const questions = [{
        question: "",
        answers: emptyAnswers,
        correct: -1,
        explanation: ""
      }];
      
      formData.append('questions', JSON.stringify(questions));
      
      // Add image files if present
      if (questionImages[0]) {
        formData.append('questionImage_0', questionImages[0]);
      }
      
      if (explanationImages[0]) {
        formData.append('explanationImage_0', explanationImages[0]);
      }

      const response = await API.post(
        `/api/trainings/training-topic/${encodeURIComponent(selectedTopicId)}/hospital/${encodeURIComponent(selectedHospitalId)}/test`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      const newTest = response.data;

      setTests((prevTests) => [...prevTests, newTest]);
      setNewTestTitle("");
      setShowNewTestInput(false);
      setSelectedTestId(testId);
      
      // Clear the image states
      handleClearAllImageStates();
    } catch (error) {
      alert(
        `Failed to create new test: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const handleDeleteTest = async (testId) => {
    if (!selectedTopicId || !selectedHospitalId) {
      alert("No topic or hospital selected");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this test?")) {
      return;
    }

    try {
      await API.delete(
        `/api/trainings/training-topic/${encodeURIComponent(
          selectedTopicId
        )}/hospital/${encodeURIComponent(
          selectedHospitalId
        )}/test/${encodeURIComponent(testId)}`
      );

      setTests((prevTests) => prevTests.filter((test) => test.id !== testId));

      if (selectedTestId === testId) {
        setSelectedTestId("");
        handleClearAllImageStates();
      }

      alert("Test deleted successfully");
    } catch (error) {
      alert(
        `Failed to delete test: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const handleAddNewQuestion = () => {
    const selectedTest = tests.find((test) => test.id === selectedTestId);
    if (!selectedTest) return;

    const newQuestion = {
      id: Date.now().toString(),
      question: "",
      answers: Array(4).fill(""),
      correct: 0,
      explanation: "",
    };

    const updatedTests = tests.map((test) => {
      if (test.id === selectedTestId) {
        return {
          ...test,
          questions: [...test.questions, newQuestion],
        };
      }
      return test;
    });

    setTests(updatedTests);
  };

  const handleDeleteQuestion = (indexToDelete) => {
    const selectedTest = tests.find((test) => test.id === selectedTestId);
    if (!selectedTest) return;

    const updatedTests = tests.map((test) => {
      if (test.id === selectedTestId) {
        return {
          ...test,
          questions: test.questions.filter(
            (_, index) => index !== indexToDelete
          ),
        };
      }
      return test;
    });

    // Clear image states for the deleted question
    handleRemoveQuestionImage(indexToDelete);
    handleRemoveExplanationImage(indexToDelete);

    setTests(updatedTests);
  };

  const handleQuestionChange = (questionIndex, field, value) => {
    const selectedTest = tests.find((test) => test.id === selectedTestId);
    if (!selectedTest) return;

    const updatedQuestions = [...selectedTest.questions];

    if (field === "answers") {
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        answers: updatedQuestions[questionIndex].answers.map((ans, idx) =>
          idx === value.index ? value.text : ans
        ),
      };
    } else if (field === "correct") {
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        [field]: parseInt(value) - 1,
      };
    } else {
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        [field]: value,
      };
    }

    const updatedTests = tests.map((test) =>
      test.id === selectedTestId
        ? { ...test, questions: updatedQuestions }
        : test
    );

    setTests(updatedTests);
  };

  const handleAddAnswer = (questionIndex) => {
    const selectedTest = tests.find((test) => test.id === selectedTestId);
    if (!selectedTest) return;

    const updatedQuestions = [...selectedTest.questions];
    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      answers: [...updatedQuestions[questionIndex].answers, ""]
    };

    const updatedTests = tests.map((test) =>
      test.id === selectedTestId
        ? { ...test, questions: updatedQuestions }
        : test
    );

    setTests(updatedTests);
  };

  const handleRemoveAnswer = (questionIndex, answerIndex) => {
    const selectedTest = tests.find((test) => test.id === selectedTestId);
    if (!selectedTest) return;

    if (selectedTest.questions[questionIndex].answers.length <= 2) {
      alert("A question must have at least 2 answers");
      return;
    }

    const updatedQuestions = [...selectedTest.questions];
    const updatedAnswers = [...updatedQuestions[questionIndex].answers];
    updatedAnswers.splice(answerIndex, 1);

    let correctAnswerIndex = updatedQuestions[questionIndex].correct;
    if (correctAnswerIndex === answerIndex) {
      correctAnswerIndex = -1;
    } else if (correctAnswerIndex > answerIndex) {
      correctAnswerIndex--;
    }

    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      answers: updatedAnswers,
      correct: correctAnswerIndex
    };

    const updatedTests = tests.map((test) =>
      test.id === selectedTestId
        ? { ...test, questions: updatedQuestions }
        : test
    );

    setTests(updatedTests);
  };

  const handleUpdateTest = async () => {
    if (!selectedTestId || !selectedTopicId || !selectedHospitalId) {
      alert("Please select a hospital, topic, and test");
      return;
    }
  
    setIsUpdating(true);
  
    try {
      const selectedTest = tests.find((test) => test.id === selectedTestId);
      
      if (!selectedTest) {
        throw new Error("Selected test not found. Please try selecting the test again.");
      }
  
      // Process questions to handle image deletions
      const processedQuestions = selectedTest.questions.map(question => {
        const processedQuestion = {...question};
        
        // Check for image deletion markers and remove the properties completely
        if (processedQuestion.questionImage && processedQuestion.questionImage.__deleted) {
          delete processedQuestion.questionImage;
        }
        
        if (processedQuestion.explanationImage && processedQuestion.explanationImage.__deleted) {
          delete processedQuestion.explanationImage;
        }
        
        return processedQuestion;
      });
  
      // Create form data for file upload
      const formData = new FormData();
      formData.append('title', selectedTest.title);
      
      // Convert processed questions array to JSON string
      formData.append('questions', JSON.stringify(processedQuestions));
      
      // Add image files for each question if present
      selectedTest.questions.forEach((question, index) => {
        // Only add image if there's no deletion marker and there's a new image
        const shouldAddQuestionImage = questionImages[index] && 
            (!question.questionImage || !question.questionImage.__deleted);
            
        const shouldAddExplanationImage = explanationImages[index] && 
            (!question.explanationImage || !question.explanationImage.__deleted);
        
        if (shouldAddQuestionImage) {
          formData.append(`questionImage_${index}`, questionImages[index]);
        }
        
        if (shouldAddExplanationImage) {
          formData.append(`explanationImage_${index}`, explanationImages[index]);
        }
      });
  
      const response = await API.put(
        `/api/trainings/training-topic/${encodeURIComponent(
          selectedTopicId
        )}/hospital/${encodeURIComponent(
          selectedHospitalId
        )}/test/${encodeURIComponent(selectedTestId)}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      
      // Update the local tests state with the response data
      const updatedTests = tests.map(test => 
        test.id === selectedTestId ? response.data : test
      );
      
      setTests(updatedTests);
      
      // Update image previews from the response data
      if (response.data.questions) {
        response.data.questions.forEach((question, index) => {
          if (question.questionImage && question.questionImage.url) {
            setQuestionImagePreviews(prev => ({
              ...prev,
              [index]: question.questionImage.url
            }));
          } else {
            // Clear preview if no image is returned from server
            setQuestionImagePreviews(prev => {
              const newPreviews = {...prev};
              delete newPreviews[index];
              return newPreviews;
            });
          }
          
          if (question.explanationImage && question.explanationImage.url) {
            setExplanationImagePreviews(prev => ({
              ...prev,
              [index]: question.explanationImage.url
            }));
          } else {
            // Clear preview if no image is returned from server
            setExplanationImagePreviews(prev => {
              const newPreviews = {...prev};
              delete newPreviews[index];
              return newPreviews;
            });
          }
        });
      }
  
      // Clear uploaded files but keep the previews
      setQuestionImages({});
      setExplanationImages({});
  
      alert("Test updated successfully!");
    } catch (error) {
      console.error("Error updating test:", error);
      alert(
        "Failed to update test: " +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCreateNewTopic = async () => {
    if (!newTopicName || !newTopicHospitalId) {
      alert("Please fill in topic name and select a hospital");
      return;
    }
  
    try {
      const requestData = {
        name: newTopicName,
        hospitalId: newTopicHospitalId
      };
  
      await API.post("/api/trainings/training-topics", requestData);
      refreshTopics();
      
      alert("New topic created successfully!");
      
      setShowNewTopicInput(false);
      setNewTopicName("");
      setNewTopicHospitalId("");
    } catch (error) {
      alert(
        `Failed to create new topic: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };
  
  const handleDeleteTopic = async () => {
    if (!selectedTopicId || !selectedHospitalId) {
      alert("Please select both a hospital and topic to delete");
      return;
    }

    if (
      !window.confirm(
        `Are you sure you want to delete the topic "${selectedTopicName}" and ALL its tests? This action cannot be undone.`
      )
    ) {
      return;
    }

    try {
      const response = await API.delete(
        `/api/trainings/training-topics/${encodeURIComponent(
          selectedTopicId
        )}`
      );

      const result = response.data;

      setSelectedTopicId("");
      setSelectedTopicName("");
      setSelectedTestId("");
      handleClearAllImageStates();
      
      refreshTopics();

      alert(
        `Topic deleted successfully! ${result.deletedTests} tests were removed.`
      );
    } catch (error) {
      alert(
        `Failed to delete topic: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const renderNewTopicInput = () => {
    return (
      <div className="training-new-test-container">
        {!showNewTopicInput ? (
          <div className="training-actions-container">
            <button
              onClick={() => setShowNewTopicInput(true)}
              className="training-button training-button-primary"
              type="button"
            >
              <BookPlus size={16} />
              הוסף נושא חדש
            </button>
            {selectedTopicId && selectedHospitalId && (
              <button
                onClick={handleDeleteTopic}
                className="training-button training-button-danger"
                type="button"
              >
                <BookX size={16} />
                מחק נושא נוכחי
              </button>
            )}
          </div>
        ) : (
          <div className="training-new-test-input">
            <div className="training-new-topic-fields">
              <CustomSelect
                label="בית חולים"
                value={newTopicHospitalId}
                onSelect={setNewTopicHospitalId}
                options={hospitals.map(h => ({
                  id: h._id,
                  label: h.nameHebrew,
                  icon: Building
                }))}
                placeholder="בחר בית חולים"
                className="training-select-in-form"
              />
              
              <input
                type="text"
                className="training-question-input"
                placeholder="שם נושא חדש"
                value={newTopicName}
                onChange={(e) => setNewTopicName(e.target.value)}
              />
            </div>
            <div className="training-new-test-actions">
              <button
                onClick={handleCreateNewTopic}
                className="training-button training-button-primary"
                type="button"
              >
                צור נושא חדש
              </button>
              <button
                onClick={() => {
                  setShowNewTopicInput(false);
                  setNewTopicName("");
                  setNewTopicHospitalId("");
                }}
                className="training-button training-button-secondary"
                type="button"
              >
                ביטול
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderNewTestInput = () => {
    if (!selectedTopicId) return null;

    return (
      <div className="training-new-test-container">
        {!showNewTestInput ? (
          <button
            onClick={() => setShowNewTestInput(true)}
            className="training-button training-button-secondary"
            type="button"
          >
            <Plus size={16} />
            הוסף מבחן חדש
          </button>
        ) : (
          <div className="training-new-test-input">
            <div className="training-new-test-fields">
              <input
                type="text"
                className="training-question-input"
                placeholder="כותרת מבחן"
                value={newTestTitle}
                onChange={(e) => setNewTestTitle(e.target.value)}
              />
            </div>
            <div className="training-new-test-actions">
              <button
                onClick={handleCreateNewTest}
                className="training-button training-button-primary"
                type="button"
              >
                צור מבחן
              </button>
              <button
                onClick={() => {
                  setShowNewTestInput(false);
                  setNewTestTitle("");
                }}
                className="training-button training-button-secondary"
                type="button"
              >
                ביטול
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getHospitals = () => {
    return hospitals.map((hospital) => ({
      id: hospital._id,
      label: hospital.nameHebrew,
      icon: Building,
    }));
  };

  const getTopics = () => {
    return topics.map((topic) => {
      const topicId = topic.id || topic._id;
      
      return {
        id: topicId, 
        label: topic.name,
        icon: Book,
      };
    });
  };

  const getTests = () => {
    return tests
      .slice()
      .sort((a, b) => (a.order || 0) - (b.order || 0))
      .map((test) => ({
        id: test.id,
        _id: test._id,
        label: test.title || test.id,
        icon: FileText,
        order: test.order || 0,
      }));
  };

  const getSelectedTestQuestions = () => {
    if (!selectedTestId) return [];
    const selectedTest = tests.find((test) => test.id === selectedTestId);
    return selectedTest?.questions || [];
  };

  if (hospitalsLoading) return <div>Loading hospitals...</div>;

  const questions = getSelectedTestQuestions();
  const hospitalOptions = getHospitals();
  
  return (
    <div className="training-page">
      <div className="training-container">
        <div className="training-content">
          {!isCreatingNew ? (
            <>
              <div className="training-header-main">
                <h2 className="training-header-title" style={{ color: 'var(--secondary-color)' }}>
                  ניהול שאלות אימון
                </h2>
              </div>
  
              {renderNewTopicInput()}
  
              {hospitalOptions.length > 1 && (
                <CustomSelect
                  label="בחירת בית חולים"
                  value={selectedHospitalId}
                  onSelect={handleHospitalSelect}
                  options={hospitalOptions}
                  placeholder="בחר בית חולים"
                />
              )}
  
              {selectedHospitalId && (
                <CustomSelect
                  label="בחירת נושא"
                  value={selectedTopicId}
                  onSelect={handleTopicSelect}
                  options={getTopics()}
                  placeholder="בחר נושא"
                  disabled={topicsLoading}
                />
              )}
              
              {renderNewTestInput()}
  
              {selectedTopicId &&
                (testsLoading ? (
                  <div>טוען מבחנים...</div>
                ) : testsError ? (
                  <div>שגיאה בטעינת מבחנים: {testsError}</div>
                ) : (
                  <div className="training-select-wrapper">
                    <div className="training-select-header">
                      <label className="training-select-label">בחירת מבחן</label>
                      {selectedTestId && !isEditingTestName && (
                        <div className="training-selected-test-pill">
                          <FileText size={16} className="training-selected-test-icon" />
                          <span className="training-selected-test-name">
                            {tests.find(t => t.id === selectedTestId)?.title || selectedTestId}
                          </span>
                          <button
                            onClick={handleEditTestName}
                            className="training-button training-button-secondary training-button-small"
                            title="ערוך שם"
                            type="button"
                          >
                            ערוך שם
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="training-select-container">
                      <div
                        className={`training-select-button ${selectedTestId ? "selected" : ""}`}
                        onClick={() => setIsSelectingTest(prev => !prev)}
                      >
                        <span className="training-select-button-text">
                          {selectedTestId
                            ? tests.find(t => t.id === selectedTestId)?.title || "בחר מבחן"
                            : "בחר מבחן"}
                        </span>
                        <ChevronDown
                          className={`training-select-chevron ${isSelectingTest ? "open" : ""}`}
                        />
                      </div>
                      {isSelectingTest && (
                        <div className="training-select-custom-dropdown">
                          <ul className="training-select-options-list">
                          {getTests().map((option, index) => (
                              <li
                                key={option.id}
                                className={`training-select-option-item ${
                                  selectedTestId === option.id ? "selected" : ""
                                }`}
                                onClick={() => {
                                  handleTestSelect(option.id);
                                  setIsSelectingTest(false);
                                }}
                              >
                                <div className="training-select-option-content">
                                  <option.icon className="training-select-option-icon" />
                                  <span>{option.label}</span>
                                </div>
                                <div className="training-select-option-actions">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMoveTestUp(option.id, e);
                                  }}
                                  className="training-button training-button-secondary training-button-small"
                                  disabled={index === 0}
                                  title="העבר למעלה"
                                  type="button"
                                >
                                  <ChevronUp size={14} />
                                </button>

                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMoveTestDown(option.id, e);
                                  }}
                                  className="training-button training-button-secondary training-button-small"
                                  disabled={index === getTests().length - 1}
                                  title="העבר למטה"
                                  type="button"
                                >
                                  <ChevronDown size={14} />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteTest(option.id);
                                  }}
                                  className="training-button training-button-danger training-button-small"
                                  title="מחק מבחן"
                                  type="button"
                                >
                                  <Trash2 size={14} />
                                </button>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
  
              {selectedTopicId && selectedTestId && !isEditingTestName && (
                <div className="training-client-view">
                  <a 
                    href={getClientViewUrl(selectedHospitalId, selectedTopicId, selectedTestId)} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="training-button training-button-secondary"
                  >
                    <ExternalLink size={16} />
                    צפה במבחן בצד לקוח
                  </a>
                </div>
              )}
  
              {isEditingTestName && (
                <div className="training-new-test-input">
                  <div className="training-new-test-fields">
                    <input
                      type="text"
                      className="training-question-input"
                      placeholder="שם מבחן"
                      value={editedTestName}
                      onChange={(e) => setEditedTestName(e.target.value)}
                    />
                  </div>
                  <div className="training-new-test-actions">
                    <button
                      onClick={handleSaveTestName}
                      className="training-button training-button-primary training-button-small"
                      type="button"
                    >
                      שמור שם
                    </button>
                    <button
                      onClick={handleCancelEditTestName}
                      className="training-button training-button-danger training-button-small"
                      type="button"
                    >
                      ביטול
                    </button>
                  </div>
                </div>
              )}
  
              {selectedTestId && !isEditingTestName && (
                <div className="questions-list">
                  {questions.map((question, index) => (
                    <QuestionForm
                      key={`${selectedTestId}-question-${index}`}
                      question={question}
                      index={index}
                      onQuestionChange={handleQuestionChange}
                      onDeleteQuestion={handleDeleteQuestion}
                      onAddAnswer={handleAddAnswer}
                      onRemoveAnswer={handleRemoveAnswer}
                      questionImagePreview={questionImagePreviews[index]}
                      explanationImagePreview={explanationImagePreviews[index]}
                      onQuestionImageChange={handleQuestionImageChange}
                      onExplanationImageChange={handleExplanationImageChange}
                      onRemoveQuestionImage={handleRemoveQuestionImage}
                      onRemoveExplanationImage={handleRemoveExplanationImage}
                    />
                  ))}
  
                  <div className="training-actions-container">
                    <button
                      onClick={handleUpdateTest}
                      className="training-button training-button-secondary"
                      disabled={isUpdating}
                      type="button"
                    >
                      <FileUp size={16} />
                      {isUpdating ? "Updating..." : "שמור שינויים"}
                    </button>
                    <button
                      onClick={handleAddNewQuestion}
                      className="training-button training-button-primary"
                      type="button"
                    >
                      <Plus size={16} />
                      הוסף שאלה חדשה
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="training-form">
              <h2 className="training-form-title">מבחן חדש</h2>
              <div className="training-form-subtitle">
                <div>
                  בית חולים:{" "}
                   {selectedHospitalId
                    ? getHospitals().find((h) => h.id === selectedHospitalId)?.label
                    : "לא נבחר"}
                </div>
                <div>
                  נושא:{" "}
                  {selectedTopicId
                    ? getTopics().find((t) => t.id === selectedTopicId)?.label
                    : "לא נבחר"}
                </div>
                <div>
                  מבחן:{" "}
                  {selectedTestId
                    ? getTests().find((t) => t.id === selectedTestId)?.label
                    : "לא נבחר"}
                </div>
              </div>
              <button
                onClick={() => setIsCreatingNew(false)}
                className="training-button training-button-secondary mt-4"
                type="button"
              >
                חזור למסך הראשי
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Training;