export enum DoseUnit {
    MCG = 'mcg',
    MG = 'mg',
    AMPULLA = 'ampulla',
    GR = 'gr',
    MEQ = 'meq',
    ML = 'ml',
    MMOL = 'mmol',
    UNITS = 'units'
  }
  
  export enum CalcType {
    DILUTION_INSTRUCTIONS = 'DilutionInstructions',
    INFUSION_SPEED = 'InfusionSpeed'
  }

// Predicates
export interface BasePredicate {
  type: 'weight' | 'age';
}
export interface WeightPredicate extends BasePredicate{
  minWeight?: number;
  maxWeight?: number;
  type: 'weight';
}
export interface AgePredicate extends BasePredicate{
  minAge?: number;
  maxAge?: number;
  unit: string;
  type: 'age';
}
export type Predicate = WeightPredicate | AgePredicate;

// Drug-related interfaces
export interface DrugDetails {
  howToGive: string;
  dose_per_kg: number;
  dose_unit: string;
  concentration: string;
  type?: string;
  comment?: string;
  maxDose?: string;
}

export interface DrugCase {
  predicate: Predicate;
  details: DrugDetails;
}

export interface BaseDrug {
  id: string;
  name: string;
  comment?: string; 
  shouldDispalyConcentration?: boolean;
  type?: string;
  warnText?: string;
}

export interface SimpleDrug extends BaseDrug {
  howToGive: string;
  dose_range: string;
  dose_per_kg: number;
  dose_unit: string;
  concentration: string;
  maxDose?: string;
  minDose?: string | number;
  minDoseUnit?: string;
}

export interface CasedDrug extends BaseDrug {
  cases: DrugCase[];
}

export type Drug = SimpleDrug | CasedDrug;

// Drip-related interfaces

export interface DoseRange {
    min: number;
    max: number;
    units: string;
}
export interface DripDetails {
  dose_unit: string;
  allowed_dose_range?: string;
  calc_type: CalcType;
  default_dilution_volume_unit?: string;
  default_dilution_volume_ml: number;
  dose_per_kg_per_min?: number;
  dose_per_kg_per_hour?: number;
  max_dose_per_hour?: number;
  target_volume_ml_per_hour: number;
}

export interface DripCase {
  predicate: WeightPredicate;
  details: DripDetails;
}

export interface Basedrip {
  id: string;
  name: string;
}

export interface SimpleDrip extends Basedrip {
  dose_unit: string;
  allowed_dose_range?: string;
  calc_type: CalcType;
  default_dilution_volume_ml?: number;
  dose_per_kg_per_min?: number;
  dose_per_kg_per_hour?: number;
  max_dose_per_hour?: number;
  target_volume_ml_per_hour?: number;
  existing_dilution_concentration?: string;
  existing_dilution_concentration_dose_unit?: string;
}

export interface CasedDrip extends Basedrip {
  cases: DripCase[];
}

export type Drip = SimpleDrip | CasedDrip;

// Root interface
export interface MedicineData {
  drugs: Drug[];
  drips: any[];
  // drips: Drip[];
}

// export interface DrugMedicine {
//     id: string;
//     name: string;
//     howToGive?: string;
//     dose_per_kg?: number;
//     dose_range?: string;
//     dose_unit?: string;
//     concentration?: string;
//     minDose?:number | string;
//     minDoseUnit?:string;
//     maxDose?: string | string;
//     comment?: string;
//     shouldDispalyConcentration?:boolean;
//     type?:string;
//     warnText?:string;
//     cases?: {              // Added cases field
//         predicate: AgeDefinition;
//         details: MedicineDetails;
//     }[];

//   }
//   export interface AgeDefinition{
//     minAge?: string | number;  // Can be either string or number in your data
//     maxAge?: string | number;
//     minWeight?: number;
//     maxWeight?: number;
//     unit?: string;
//     type: string;
// }
//   export interface MedicineDetails{
//     howToGive?: string;
//     dose_per_kg?: number;
//     dose_unit?: string;
//     concentration?: string;
//     type?: string;
//     maxDose?:string;
//     comment?: string;
//   }

//   export interface DripMedicine {
//     id: string;
//     name: string;
//     dose_unit: string;
//     allowed_dose_range?: string;
//     calc_type?: string;
//     default_dilution_volume_unit?:string;
//     default_dilution_volume_ml?: number;
//     dose_per_kg_per_min?: number;
//     dose_per_kg_per_hour?: number;
//     max_dose_per_hour?:number;
//     definition_by_weights?: WeightDefinition[];
//     target_volume_ml_per_hour?: number;
//     existing_dilution_concentration?: string;
//     existing_dilution_concentration_dose_unit?: string;
//     comment?: string;
//   }
// export interface WeightDefinition {
//     min_kg: number;
//     max_kg: number;
//     target_volume_ml_per_hour: number;
//   }

export interface CategoryDefinition {
        name: string;
        drugs: string[];
}

export interface ProtocolDefinition {
  protocolId: string;
  drugs?: string[];
  drips?: string[];
  defi?: DefiDefinition[];
  drugComments?: { [key: string]: string }; // Changed to store direct strings
}

export interface DefiDefinition {
    name: string;
    joulePerKg: number;
}
// export type DrugCommentsMap = Record<string, string>;
