import { useState, useEffect } from "react";
import API from "../../../config/axiosConfig";

/**
 * Custom hook for fetching training tests
 * @param {string} selectedTopicId - ID of the selected topic
 * @param {string} selectedHospitalId - ID of the selected hospital
 * @returns {Object} Object containing tests data, loading state, error state, raw data, topic data, setTests function, and hasPermission flag
 */
const useTrainingTests = (selectedTopicId, selectedHospitalId) => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [topicData, setTopicData] = useState(null);
  const [hasPermission, setHasPermission] = useState(true);

  useEffect(() => {
    const fetchTests = async () => {
      if (!selectedTopicId || !selectedHospitalId) {
        setTests([]);
        setRawData(null);
        setTopicData(null);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const response = await API.get(
          `/api/trainings/training-topic/${encodeURIComponent(selectedTopicId)}/hospital/${encodeURIComponent(selectedHospitalId)}`
        );
        const data = response.data;

        setRawData(data);
        setHasPermission(true);
        
        if (data.tests && data.topic) {
          setTopicData(data.topic);
          
          const formattedData = Array.isArray(data.tests)
            ? data.tests.map((item) => ({
                ...item,
                id: String(item.id || item._id),
                _id: item._id,
                questions: Array.isArray(item.questions)
                  ? item.questions.map((q) => ({
                      ...q,
                      id: String(q.id || q._id),
                      correct: q.correct,
                    }))
                  : [],
              }))
            : [];

          setTests(formattedData);
        }
        
        setLoading(false);
      } catch (err) {
        console.error("Error fetching tests:", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          setHasPermission(false);
          setTests([]);
          setError("No permission to view tests for this topic");
        } else {
          setError(err.response?.data?.message || "Failed to fetch tests");
        }
        setLoading(false);
      }
    };

    fetchTests();
  }, [selectedTopicId, selectedHospitalId]);

  return { tests, loading, error, rawData, topicData, setTests, hasPermission };
};

export default useTrainingTests;