import React, { useState, useEffect, useContext } from 'react';
import { 
  Container, Typography, TextField, Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, InputAdornment, FormControl, Select, MenuItem, InputLabel
} from '@mui/material';
import { UserContext } from '../../context/UserContext';
import API from '../../config/axiosConfig';
import Loader from '../../components/Loader';
import AddEditTestTube from './AddEditTestTube';
import './TestTubes.css';

// SVG Icons
const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" fill="currentColor"/>
  </svg>
);

const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" fill="currentColor"/>
  </svg>
);

const AddIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="currentColor"/>
  </svg>
);

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="currentColor"/>
  </svg>
);

const TestTubeManagement = () => {
  const [testTubes, setTestTubes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [currentTestTube, setCurrentTestTube] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState('');
  const [availableHospitals, setAvailableHospitals] = useState([]);
  const [hospitalsWithEditAccess, setHospitalsWithEditAccess] = useState([]);
  
  // User context for permissions
  const { user, hospitals } = useContext(UserContext);

  // Constants for configuration storage
  const APP_NAME = 'test-tube';
  const CONFIG_NAME = 'test-tube';

  useEffect(() => {
    // Set up available hospitals based on user permissions
    if (hospitals && hospitals.length > 0 && user) {
      // Filter hospitals where user has edit access (EDITOR or ADMIN)
      const editableHospitals = hospitals.filter(hospital => {
        if (user.isSuperAdmin) return true;
        
        const userRole = user.roles.find(role => 
          role.hospital === hospital._id || 
          role.hospital.toString() === hospital._id.toString()
        );
        
        return userRole && ['EDITOR', 'ADMIN'].includes(userRole.role);
      });
      
      setHospitalsWithEditAccess(editableHospitals);
      
      let hospitalOptions = [...hospitals];
      
      // Add "apps" option for super admin
      if (user.isSuperAdmin) {
        hospitalOptions.push({
          _id: "apps",
          nameHebrew: "ברירת מחדל",
          nameEnglish: "apps"
        });
      }
      
      setAvailableHospitals(hospitalOptions);
      
      // Set default hospital selection
      if (editableHospitals.length === 1) {
        // If user has edit access to only one hospital, select it automatically
        setSelectedHospital(editableHospitals[0].nameEnglish);
      } else if (user.isSuperAdmin && hospitalOptions.length > 0) {
        // Default to "apps" for super admin with multiple options
        setSelectedHospital("apps");
      } else if (editableHospitals.length > 0) {
        // Default to first editable hospital
        setSelectedHospital(editableHospitals[0].nameEnglish);
      } else if (hospitalOptions.length > 0) {
        // Fallback to first hospital if no editable ones
        setSelectedHospital(hospitalOptions[0].nameEnglish);
      }
    }
  }, [hospitals, user]);

  // Filter test tubes based on search term
  const filteredTestTubes = testTubes.filter(tube => {
    if (!searchTerm.trim()) return true; // Show all if search is empty
    
    const lowercasedSearch = searchTerm.toLowerCase();
    
    // If keywords is an array, check if any of the keywords include the search term
    const keywordsMatch = Array.isArray(tube.keywords) && 
      tube.keywords.some(keyword => keyword.toLowerCase().includes(lowercasedSearch));
      
    return (
      (tube.mainTitle && tube.mainTitle.toLowerCase().includes(lowercasedSearch)) ||
      (tube.shortName && tube.shortName.toLowerCase().includes(lowercasedSearch)) ||
      keywordsMatch ||
      (tube.notes && tube.notes.toLowerCase().includes(lowercasedSearch))
    );
  });

  // Fetch test tubes from the server
  const fetchTestTubes = async (hospital) => {
    if (!hospital) return;
    
    try {
      setLoading(true);
      const response = await API.get(`/api/configs/${APP_NAME}/${CONFIG_NAME}/${hospital}`);
      if (response.data.success) {
        setTestTubes(response.data.data.tubes || []);
      }
    } catch (error) {
      console.error('Error fetching test tubes:', error);
      showNotification('שגיאה בטעינת נתונים', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Save all test tubes to the server using the unified endpoint
  const saveTestTubes = async (updatedTestTubes) => {
    if (!selectedHospital) return;
    
    try {
      const response = await API.post(`/api/configs/${APP_NAME}/${CONFIG_NAME}/${selectedHospital}`, {
        data: { "tubes" : updatedTestTubes }
      });
      if (response.data.success) {
        setTestTubes(response.data.data.tubes || []);
        showNotification('הנתונים נשמרו בהצלחה', 'success');
      }
    } catch (error) {
      console.error('Error saving test tubes:', error);
      showNotification('שגיאה בשמירת הנתונים', 'error');
      throw error; // Re-throw to handle in the calling function
    }
  };

  useEffect(() => {
    if (selectedHospital) {
      fetchTestTubes(selectedHospital);
    }
  }, [selectedHospital]);

  const handleHospitalChange = (event) => {
    const newHospital = event.target.value;
    setSelectedHospital(newHospital);
  };

  const showNotification = (message, severity = 'success') => {
    setNotification({
      open: true,
      message,
      severity
    });
    
    // Auto-hide after 3 seconds
    setTimeout(() => {
      setNotification(prev => ({ ...prev, open: false }));
    }, 3000);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handleClickOpen = (testTube, index) => {
    if (testTube) {
      setCurrentTestTube({...testTube});
      setEditMode(true);
      setEditIndex(index);
    } else {
      setCurrentTestTube({
        mainTitle: '',
        shortName: '',
        performingLaboratory: '',
        sampleSource: '',
        collectionTool: '',
        color: '',
        collectionInstructions: '',
        clarificationsAndExplanations: '',
        notes: '',
        keywords: []
      });
      setEditMode(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentTestTube(null);
  };

  const handleSave = async (testTubeData) => {
    if (!selectedHospital) {
      showNotification('יש לבחור בית חולים תחילה', 'error');
      return;
    }
    
    setIsSubmitting(true);
    let updatedTestTubes;
    if (editMode) {
      updatedTestTubes = [...testTubes];
      updatedTestTubes[editIndex] = testTubeData;
    } else {
      updatedTestTubes = [...testTubes, testTubeData];
    }

    try {
      await saveTestTubes(updatedTestTubes);
      handleClose();
    } catch (error) {
      console.error('Error saving test tube:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openDeleteConfirm = (e, index) => {
    e.stopPropagation();
    setDeleteIndex(index);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    if (deleteIndex !== null) {
      setIsSubmitting(true);
      try {
        const updatedTestTubes = [...testTubes];
        updatedTestTubes.splice(deleteIndex, 1);
        await saveTestTubes(updatedTestTubes);
        setDeleteConfirmOpen(false);
        setDeleteIndex(null);
      } catch (error) {
        console.error('Error deleting test tube:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Check if user has edit permissions for the currently selected hospital
  const hasEditPermissionForSelectedHospital = () => {
    if (!user || !selectedHospital) return false;
    
    // Super admin has permissions for all hospitals
    if (user.isSuperAdmin) return true;
    
    // Find the hospital object that matches the selected hospital name
    const selectedHospitalObj = hospitals.find(h => h.nameEnglish === selectedHospital);
    if (!selectedHospitalObj) return false;
    
    // Check if user has EDITOR or ADMIN role for this hospital
    return user.roles.some(role => {
      const hospitalId = selectedHospitalObj._id.toString();
      const roleHospitalId = typeof role.hospital === 'object' 
        ? role.hospital._id.toString() 
        : role.hospital.toString();
        
      return roleHospitalId === hospitalId && ['EDITOR', 'ADMIN'].includes(role.role);
    });
  };

  // Check general user permissions (used for edit/delete buttons)
  const canManageTestTubes = () => {
    return hasEditPermissionForSelectedHospital();
  };

  // Determine if we should show the hospital selector
  const shouldShowHospitalSelector = () => {
    // Only show selector if user has edit permissions for more than one hospital
    return hospitalsWithEditAccess.length > 1 || (user?.isSuperAdmin && hospitals.length > 0);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} dir="rtl" className="test-tubes-container">
      <Typography variant="h4" component="h1" gutterBottom align="center">
        ניהול מבחנות מעבדה
      </Typography>
      
      {/* Hospital Selector - Only show if user has edit permissions for multiple hospitals */}
      {shouldShowHospitalSelector() && (
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="hospital-select-label">בית חולים</InputLabel>
          <Select
            labelId="hospital-select-label"
            id="hospital-select"
            value={selectedHospital}
            label="בית חולים"
            onChange={handleHospitalChange}
          >
            {/* Only include hospitals where user has edit access or is superadmin */}
            {user?.isSuperAdmin ? (
              // Super admin can see all hospitals plus "apps"
              availableHospitals.map((hospital) => (
                <MenuItem key={hospital._id} value={hospital.nameEnglish}>
                  {hospital.nameHebrew}
                </MenuItem>
              ))
            ) : (
              // Regular users only see hospitals they can edit
              hospitalsWithEditAccess.map((hospital) => (
                <MenuItem key={hospital._id} value={hospital.nameEnglish}>
                  {hospital.nameHebrew}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      )}
      
      {/* Search Box */}
      <div className="search-box">
        <TextField
          fullWidth
          placeholder="חיפוש לפי כותרת, שם מקוצר, מילות מפתח או הערות..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: { borderRadius: '8px' }
          }}
          sx={{ 
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px'
            }
          }}
        />
      </div>

      {/* Loading Overlay */}
      <Loader isLoading={loading} />

      {/* Test Tubes List */}
      <div className="test-tubes-list">
        {!loading && testTubes.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Typography>לא נמצאו מבחנות. הוסף מבחנה חדשה כדי להתחיל.</Typography>
          </div>
        ) : !loading && filteredTestTubes.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Typography>לא נמצאו תוצאות התואמות לחיפוש שלך.</Typography>
          </div>
        ) : (
          filteredTestTubes.map((testTube, index) => (
            <div 
              key={index}
              className="test-tube-item"
              onClick={() => canManageTestTubes() && handleClickOpen(testTube, index)}
              style={{ position: 'relative', cursor: canManageTestTubes() ? 'pointer' : 'default' }}
            >
              {/* Color indicator on the left side */}
              {testTube.color && (
                <div 
                  className="color-indicator" 
                  style={{ backgroundColor: testTube.color }}
                />
              )}
              
              <div className="test-tube-info">
                <div className="test-tube-title">{testTube.mainTitle}</div>
                
                {testTube.keywords && testTube.keywords.length > 0 && (
                  <div className="test-tube-keywords">
                    {testTube.keywords.map((kw, i) => (
                      <span key={i} className="test-tube-keyword">{kw}</span>
                    ))}
                  </div>
                )}
              </div>
              
              {canManageTestTubes() && (
                <div 
                  className="test-tube-actions"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="action-icon" onClick={(e) => {
                    e.stopPropagation();
                    handleClickOpen(testTube, index);
                  }}>
                    <EditIcon />
                  </div>
                  <div className="action-icon" onClick={(e) => openDeleteConfirm(e, index)}>
                    <DeleteIcon />
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {/* Floating Add Button - Only show if user has edit permissions for the selected hospital */}
      {hasEditPermissionForSelectedHospital() && selectedHospital && (
        <button className="floating-add-btn" onClick={() => handleClickOpen(null)}>
          <AddIcon />
        </button>
      )}

      {/* Add/Edit Test Tube Dialog */}
      {open && (
        <AddEditTestTube
          open={open}
          onClose={handleClose}
          testTube={currentTestTube}
          editMode={editMode}
          onSave={handleSave}
          isSubmitting={isSubmitting}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        dir="rtl"
      >
        <DialogTitle>
          האם אתה בטוח שברצונך למחוק את המבחנה?
        </DialogTitle>
        <DialogActions style={{ justifyContent: 'center', padding: '16px 24px' }}>
          <Button 
            onClick={() => setDeleteConfirmOpen(false)}
            style={{ marginLeft: '16px' }}
          >
            ביטול
          </Button>
          <Button
            onClick={handleDelete}
            style={{
              backgroundColor: '#f44336',
              color: 'white',
              minWidth: '120px'
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'מוחק...' : 'מחק'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notification */}
      {notification.open && (
        <div style={{ 
          position: 'fixed', 
          bottom: '20px', 
          left: '20px', 
          zIndex: 2000,
          backgroundColor: notification.severity === 'error' ? '#f44336' : '#4caf50',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '4px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{notification.message}</div>
            <button 
              onClick={handleCloseNotification}
              style={{ 
                marginRight: '10px', 
                background: 'none', 
                border: 'none', 
                color: 'white',
                cursor: 'pointer',
                fontSize: '16px'
              }}
            >
              ×
            </button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default TestTubeManagement;