import React, { useState, useEffect } from 'react';
import { Drug, BaseDrug, CasedDrug, SimpleDrug, DrugCase, Predicate, AgePredicate, WeightPredicate} from './types';


interface MedicineFormProps {
  onSubmit: (drug: Drug) => void;
  onCancel: () => void;
  editingMedicine?: Drug | null;
}

const MedicineForm: React.FC<MedicineFormProps> = ({ onSubmit, onCancel, editingMedicine }) => {
    const [drugType, setDrugType] = useState<'simple' | 'cased'>(editingMedicine ? 
        ('cases' in editingMedicine ? 'cased' : 'simple') : 'simple');
    
      // Base drug fields
  const [id, setId] = useState(editingMedicine?.id || 'tmpId'); // for autogenerate ID when missing, need to replace with different rule "|| uuidv4()"
  const [name, setName] = useState(editingMedicine?.name || '');
  const [comment, setComment] = useState(editingMedicine?.comment || '');
  const [type, setType] = useState(editingMedicine?.type || '');
  const [warnText, setWarnText] = useState(editingMedicine?.warnText || '');
  const [shouldDisplayConcentration, setShouldDisplayConcentration] = 
    useState(editingMedicine?.shouldDispalyConcentration || false);

  // Simple drug fields
  const [howToGive, setHowToGive] = useState('');
  const [doseRange, setDoseRange] = useState('');
  const [dosePerKg, setDosePerKg] = useState('');
  const [doseUnit, setDoseUnit] = useState('');
  const [concentration, setConcentration] = useState('');
  const [maxDose, setMaxDose] = useState('');
  const [minDose, setMinDose] = useState('');
  const [minDoseUnit, setMinDoseUnit] = useState('');

  // Cased drug fields
  const [cases, setCases] = useState<DrugCase[]>([]);

// Initialize form with existing medicine data
    useEffect(() => {
        if (editingMedicine) {
            if ('cases' in editingMedicine) {
            setDrugType('cased');
            setCases(editingMedicine.cases);
            } else {
            setId('tmpId');
            setDrugType('simple');
            setHowToGive(editingMedicine.howToGive);
            setDoseRange(editingMedicine.dose_range);
            setDosePerKg(editingMedicine.dose_per_kg.toString());
            setDoseUnit(editingMedicine.dose_unit);
            setConcentration(editingMedicine.concentration);
            setMaxDose(editingMedicine.maxDose || '');
            setMinDose(editingMedicine.minDose?.toString() || '');
            setMinDoseUnit(editingMedicine.minDoseUnit || '');
            }
        }
    }, [editingMedicine]);

    const addCase = () => {
        setCases([...cases, {
            predicate: { type: 'weight' },
            details: {
            howToGive: '',
            dose_per_kg: 0,
            dose_unit: '',
            concentration: '',
            maxDose: ''
            }
        }]);
    };
    
    const updateCase = (index: number, updatedCase: DrugCase) => {
        const newCases = [...cases];
        newCases[index] = updatedCase;
        setCases(newCases);
    };
    
    const removeCase = (index: number) => {
        setCases(cases.filter((_, i) => i !== index));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
            let updatedHowToGive = howToGive;
            let updatedDosePerKg = dosePerKg;
            let updatedDoseUnit = doseUnit;
            if (drugType === 'simple') {
                if (updatedHowToGive.match(/(^|[^\/\\])(IV)([^\/\\]|$)/)) {
                    console.log ('IV');
                    updatedHowToGive = 'IV';
                } else if (updatedHowToGive.match(/.*(PZ).*/)){
                    console.log ('PZ');
                    updatedHowToGive = 'PZ';
                } else if (updatedHowToGive.match(/.*(Endotracheal).*/)){
                    console.log ('ET');
                    updatedHowToGive = 'ET';
                }
            } else if (drugType === 'cased'){
                updatedHowToGive = cases[0].details.howToGive;
                updatedDosePerKg = cases.map (c => c.details.dose_per_kg).join("_");
                updatedDoseUnit = cases[0].details.dose_unit;
            }
			const updatedId = `${name}_${updatedHowToGive}_${updatedDosePerKg}_${updatedDoseUnit}`;
			// console.log ('*** handleSubmit', {id, updatedId, updatedDosePerKg});
            setId(updatedId);
        const baseDrug: BaseDrug = {
            id: updatedId,
            name,
            comment: comment || undefined,
            type: type || undefined,
            warnText: warnText || undefined,
            shouldDispalyConcentration: shouldDisplayConcentration
        };

        if (drugType === 'simple') {
            const simpleDrug: SimpleDrug = {
            ...baseDrug,
            howToGive,
            dose_range: doseRange,
            dose_per_kg: parseFloat(dosePerKg),
            dose_unit: doseUnit,
            concentration,
            maxDose: maxDose || undefined,
            minDose: minDose ? parseFloat(minDose) : undefined,
            minDoseUnit: minDoseUnit || undefined
            };
            onSubmit(simpleDrug);
        } else {
            const casedDrug: CasedDrug = {
            ...baseDrug,
            cases: cases.map(caseItem => ({
                ...caseItem,
                details: {
                ...caseItem.details,
                dose_per_kg: parseFloat(caseItem.details.dose_per_kg.toString())
                }
            }))
            };
            onSubmit(casedDrug);
        }
        onCancel();
    };

    const renderCaseFields = (caseItem: DrugCase, index: number) => (
        <div key={index} className="border p-3 mb-3">
          <h6>Case {index + 1}</h6>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Predicate Type</label>
              <select 
                className="form-control"
                value={caseItem.predicate.type}
                onChange={(e) => {
                  const newPredicate: Predicate = 
                    e.target.value === 'weight' 
                    ? { type: 'weight'}   // min/max weight are optional numbers, alternative can be set to 0
                    : { type: 'age', unit: 'years' }; // min/max age are optional numbers, alternative can be set to 0
                  updateCase(index, { 
                    ...caseItem, 
                    predicate: newPredicate 
                  });
                }}
              >
                <option value="weight">Weight</option>
                <option value="age">Age</option>
              </select>
            </div>
            {caseItem.predicate.type === 'weight' ? (
              <>
                <div className="col-md-6 mb-3">
                  <label>Min Weight (kg)</label>
                  <input 
                    type="number" 
                    className="form-control"
                    value={(caseItem.predicate as WeightPredicate).minWeight||''}
                    onChange={(e) => {
                        const updatedPredicate: WeightPredicate = {
                            ...caseItem.predicate as WeightPredicate,
                            minWeight: e.target.value ? parseFloat(e.target.value) : undefined
                           }
                            updateCase(index, {
                            ...caseItem,
                            predicate: updatedPredicate
                        })
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>Max Weight (kg)</label>
                  <input 
                    type="number" 
                    className="form-control"
                    value={(caseItem.predicate as WeightPredicate).maxWeight || ''}
                    onChange={(e) => {
                        const updatedPredicate: WeightPredicate = {
                            ...caseItem.predicate as WeightPredicate,
                            maxWeight: e.target.value ? parseFloat(e.target.value) : undefined
                           }
                            updateCase(index, {
                            ...caseItem,
                            predicate: updatedPredicate
                        })
                    }}
                  />
                </div>              
              </>
            ) : (
              <>
                <div className="col-md-6 mb-3">
                  <label>Min Age</label>
                  <input 
                    type="number" 
                    className="form-control"
                    value={(caseItem.predicate as AgePredicate).minAge || ''}
                    onChange={(e) => {
                        const updatedPredicate: AgePredicate = {
                        ...caseItem.predicate as AgePredicate,
                        minAge: e.target.value ? parseFloat(e.target.value) : undefined
                        };
                        updateCase(index, {
                        ...caseItem,
                        predicate: updatedPredicate
                        });
                    }}
                    />
                </div>
                <div className="col-md-6 mb-3">
                  <label>Max Age</label>
                  <input 
                    type="number" 
                    className="form-control"
                    value={(caseItem.predicate as AgePredicate).maxAge || ''}
                    onChange={(e) => {
                        const updatedPredicate: AgePredicate = {
                        ...caseItem.predicate as AgePredicate,
                        maxAge: e.target.value ? parseFloat(e.target.value) : undefined
                        };
                        updateCase(index, {
                        ...caseItem,
                        predicate: updatedPredicate
                        });
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>Age Unit</label>
                  <select 
                    className="form-control"
                    value={(caseItem.predicate as AgePredicate).unit || 'years'}
                    onChange={(e) => {
                        const updatedPredicate: AgePredicate = {
                        ...caseItem.predicate as AgePredicate,
                        unit: e.target.value 
                        };
                        updateCase(index, {
                        ...caseItem,
                        predicate: updatedPredicate
                        });
                    }}
                  >
                    <option value="years">Years</option>
                    <option value="months">Months</option>
                  </select>
                </div>
              </>
            )}
            <div className="col-md-6 mb-3">
              <label>How to Give</label>
              <input 
                type="text" 
                className="form-control"
                value={caseItem.details.howToGive}
                onChange={(e) => updateCase(index, {
                  ...caseItem,
                  details: { 
                    ...caseItem.details, 
                    howToGive: e.target.value 
                  }
                })}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Dose per kg</label>
              <input 
                type="number" 
                className="form-control"
                value={caseItem.details.dose_per_kg}
                onChange={(e) => updateCase(index, {
                  ...caseItem,
                  details: { 
                    ...caseItem.details, 
                    dose_per_kg: parseFloat(e.target.value) 
                  }
                })}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Dose Unit</label>
              <input 
                type="text" 
                className="form-control"
                value={caseItem.details.dose_unit}
                onChange={(e) => updateCase(index, {
                  ...caseItem,
                  details: { 
                    ...caseItem.details, 
                    dose_unit: e.target.value 
                  }
                })}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Concentration</label>
              <input 
                type="text" 
                className="form-control"
                value={caseItem.details.concentration}
                onChange={(e) => updateCase(index, {
                  ...caseItem,
                  details: { 
                    ...caseItem.details, 
                    concentration: e.target.value 
                  }
                })}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Max Dose</label>
              <input 
                type="text" 
                className="form-control"
                value={caseItem.details.maxDose || ''}
                onChange={(e) => updateCase(index, {
                  ...caseItem,
                  details: { 
                    ...caseItem.details, 
                    maxDose: e.target.value 
                  }
                })}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Comment</label>
              <input 
                type="text" 
                className="form-control"
                value={caseItem.details.comment || ''}
                onChange={(e) => updateCase(index, {
                  ...caseItem,
                  details: { 
                    ...caseItem.details, 
                    comment: e.target.value 
                  }
                })}
              />
            </div>
            <div className="col-md-12 mb-3">
              <button 
                type="button" 
                className="btn btn-danger"
                onClick={() => removeCase(index)}
              >
                Remove Case
              </button>
            </div>
          </div>
        </div>
    );


    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label>Drug Type</label>
                    <select 
                        className="form-select"
                        value={drugType}
                        onChange={(e) => {
                        setDrugType(e.target.value as 'simple' | 'cased');
                        if (e.target.value === 'cased') {
                            setCases([{
                            predicate: { type: 'weight'},
                            details: {
                                howToGive: '',
                                dose_per_kg: 0,
                                dose_unit: '',
                                concentration: '',
                                maxDose: ''
                            }
                            }]);
                        }
                        }}
                    > 
                        <option value="simple">Simple Drug</option>
                        <option value="cased">Cased Drug</option>
                    </select>
                </div>

                <div className="col-md-6 mb-3">
                    <label>Name*</label>
                    <input 
                        type="text" 
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required 
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <label>Type</label>
                    <input 
                        type="text" 
                        className="form-control"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    />
                </div>
                
            </div>
            
            {drugType === 'simple' && (
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label>How to Give*</label>
                        <input 
                        type="text" 
                        className="form-control"
                        value={howToGive}
                        onChange={(e) => setHowToGive(e.target.value)}
                        required
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Dose Range</label>
                        <input 
                        type="text" 
                        className="form-control"
                        value={doseRange}
                        onChange={(e) => setDoseRange(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Dose per kg*</label>
                        <input 
                        type="number" 
                        className="form-control"
                        value={dosePerKg}
                        onChange={(e) => setDosePerKg(e.target.value)}
                        required
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Dose Unit*</label>
                        <input 
                        type="text" 
                        className="form-control"
                        value={doseUnit}
                        onChange={(e) => setDoseUnit(e.target.value)}
                        required
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Min Dose</label>
                        <input 
                        type="text" 
                        className="form-control"
                        value={minDose}
                        onChange={(e) => setMinDose(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Min Dose Unit</label>
                        <input 
                        type="text" 
                        className="form-control"
                        value={minDoseUnit}
                        onChange={(e) => setMinDoseUnit(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Max Dose</label>
                        <input 
                        type="text" 
                        className="form-control"
                        value={maxDose}
                        onChange={(e) => setMaxDose(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Concentration</label>
                        <input 
                        type="text" 
                        className="form-control"
                        value={concentration}
                        onChange={(e) => setConcentration(e.target.value)}
                        />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label> Display Concentration</label>
                        <select 
                            className="form-select"
                            value={shouldDisplayConcentration ? 'yes' : 'no'}
                            onChange={(e) => setShouldDisplayConcentration(e.target.value === 'yes')}
                        >   
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>Comment</label>
                        <textarea 
                            className="form-control"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>Warning Text</label>
                        <input 
                            type="text" 
                            className="form-control"
                            value={warnText}
                            onChange={(e) => setWarnText(e.target.value)}
                        />
                    </div>
                </div>
            )}

            {drugType === 'cased' && (
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Cases</h5>
                        <button 
                        type="button" 
                        className="btn btn-primary"
                        onClick={addCase}
                        >
                        Add Case
                        </button>
                    </div>
                    {cases.map((caseItem, index) => renderCaseFields(caseItem, index))}
                </div>
            )}


            <div className="mt-4 d-flex justify-content-end gap-2">
                <button type="button" className="btn btn-secondary" onClick={onCancel}>
                   Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {editingMedicine ? 'Update' : 'Add'} Drug
                </button>
            </div>
        </form>
    );
};
export default MedicineForm;