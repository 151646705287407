import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Configure worker to use file from public folder
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const SimplePDFViewer = ({ file, onError }) => {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.6);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const zoomIn = () => setScale((prev) => Math.min(prev + 0.2, 3));
  const zoomOut = () => setScale((prev) => Math.max(prev - 0.2, 0.5));

  return (
    <div>
      <div className="pdf-controls">
        <button onClick={zoomOut} className="zoom-button" title="Zoom out">
          −
        </button>
        <span className="zoom-level">{Math.round(scale * 100)}%</span>
        <button onClick={zoomIn} className="zoom-button" title="Zoom in">
          +
        </button>
        {numPages && (
          <span className="page-count">Total Pages: {numPages}</span>
        )}
      </div>

      <div className="pdf-container">
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onError}
          loading={<div className="pdf-loading">Loading PDF...</div>}
          error={<div className="pdf-error">Failed to load PDF</div>}
        >
          {Array.from(new Array(numPages), (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={scale}
              width={Math.min(window.innerWidth * 0.9, 1000)}
              loading={<div className="page-loading">Loading page...</div>}
              error={<div className="page-error">Error loading page</div>}
            />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default SimplePDFViewer;
