import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Pencil, Trash2, Heart } from 'lucide-react';
import { UserContext } from '../context/UserContext';
import CommonLayout from '../components/CommonLayout';
import './Cases.css';
import { mockCases } from '../utils/mockData';

const Cases = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [cases, setCases] = useState(mockCases);
  const { user } = useContext(UserContext);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [caseToDelete, setCaseToDelete] = useState(null);
  const [toast, setToast] = useState({ show: false, message: '' });

  const filteredCases = cases.filter(
    (case_) =>
      case_.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      case_.tags.some((tag) =>
        tag.toLowerCase().includes(searchQuery.toLowerCase())
      )
  );

  const canEditCase = () => {
    return (
      user &&
      (user.isSuperAdmin ||
        user.roles.some((role) => ['EDITOR', 'ADMIN'].includes(role.role)))
    );
  };

  const handleDeleteClick = (e, caseId) => {
    e.stopPropagation();
    const caseItem = cases.find((c) => c.id === caseId);
    setCaseToDelete(caseItem);
    setShowDeleteConfirm(true);
  };

  const confirmDelete = () => {
    if (caseToDelete) {
      setCases(cases.filter((c) => c.id !== caseToDelete.id));
      setShowDeleteConfirm(false);
      setCaseToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirm(false);
    setCaseToDelete(null);
  };

  const handleFavoriteClick = (e, caseItem) => {
    e.stopPropagation(); // Prevent card click
    setToast({
      show: true,
      message: `הוספת "${caseItem.title}" למועדפים`,
    });

    // Hide toast after 3 seconds
    setTimeout(() => {
      setToast({ show: false, message: '' });
    }, 3000);
  };

  // Clear toast on unmount
  useEffect(() => {
    return () => {
      if (toast.show) {
        clearTimeout();
      }
    };
  }, [toast.show]);

  return (
    <CommonLayout>
      <div id="cases" className="page-container">
        <div className="page-header">
          <h1 className="page-title">מקרים רפואיים</h1>
          <div className="search-container">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              type="text"
              className="search-input"
              placeholder="חיפוש לפי כותרת או תגיות..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="cards-grid">
          {filteredCases.map((case_) => (
            <div
              key={case_.id}
              className="case-card"
              onClick={() => navigate(`/cases/${case_.id}/view`)}
            >
              <div className="case-image">
                <img src={case_.imageUrl} alt={case_.title} />
                <button
                  className="favorite-button"
                  onClick={(e) => handleFavoriteClick(e, case_)}
                  aria-label="הוסף למועדפים"
                >
                  <Heart size={16} />
                </button>
              </div>
              <div className="case-content">
                <h3 className="case-title">{case_.title}</h3>
                <div className="tags-container">
                  {case_.tags.map((tag, index) => (
                    <span key={index} className="tag">
                      {tag}
                    </span>
                  ))}
                </div>
                {canEditCase() && (
                  <div className="card-actions">
                    <button
                      className="action-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/cases/${case_.id}/edit`);
                      }}
                    >
                      <Pencil size={16} />
                    </button>
                    <button
                      className="action-button delete"
                      onClick={(e) => handleDeleteClick(e, case_.id)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {canEditCase() && (
          <button
            className="floating-add-btn"
            onClick={() => navigate('/cases/new')}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        )}

        {showDeleteConfirm && (
          <div className="delete-modal-overlay">
            <div className="delete-modal">
              <h3>אישור מחיקה</h3>
              <p>האם אתה בטוח שברצונך למחוק את "{caseToDelete?.title}"?</p>
              <div className="delete-modal-actions">
                <button className="cancel-btn" onClick={cancelDelete}>
                  ביטול
                </button>
                <button className="confirm-btn" onClick={confirmDelete}>
                  אישור מחיקה
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Toast notification */}
        {toast.show && (
          <div className="toast-notification">{toast.message}</div>
        )}
      </div>
    </CommonLayout>
  );
};

export default Cases;
