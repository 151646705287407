export const mockCases = [
  {
    id: "1",
    title: "שבר מורכב בכף הרגל",
    description: `טיפול בשבר מורכב עם גישה חדשנית. המקרה כלל טיפול ייחודי באמצעות טכניקה חדשה.

הטיפול כלל מספר שלבים:
1. אבחון מדויק באמצעות הדמיה מתקדמת
2. תכנון הניתוח בעזרת מודל תלת-ממדי
3. ביצוע הניתוח בגישה זעיר-פולשנית
4. תהליך שיקום מותאם אישית`,
    tags: ["אורתופדיה", "טראומה", "שבר"],
    imageUrl: "https://picsum.photos/seed/foot/800/400",
    createdAt: "2024-02-15T10:30:00Z",
    updatedAt: "2024-02-15T10:30:00Z",
  },
  {
    id: "2",
    title: "דלקת ריאות חריפה",
    description: "מקרה מעניין של דלקת ריאות עם סיבוכים. הטיפול כלל גישה חדשנית להתמודדות עם הזיהום.",
    tags: ["ריאות", "דלקת", "חריף"],
    imageUrl: "https://picsum.photos/seed/lung/800/400",
    createdAt: "2024-02-14T09:15:00Z",
    updatedAt: "2024-02-14T09:15:00Z",
  },
  {
    id: "3",
    title: "ניתוח לב פתוח",
    description: "ניתוח מורכב עם טכניקה חדשנית לתיקון מום מולד בלב.",
    tags: ["לב", "ניתוח", "חדשני"],
    imageUrl: "https://picsum.photos/seed/heart/800/400",
    createdAt: "2024-02-13T14:20:00Z",
    updatedAt: "2024-02-13T14:20:00Z",
  },
  {
    id: "4",
    title: "טיפול בגידול נדיר",
    description: "גישה חדשה לטיפול בגידול מורכב באמצעות טכנולוגיה מתקדמת.",
    tags: ["אונקולוגיה", "גידול", "חדשני"],
    imageUrl: "https://picsum.photos/seed/tumor/800/400",
    createdAt: "2024-02-12T11:45:00Z",
    updatedAt: "2024-02-12T11:45:00Z",
  }
];