import React from "react";
import PropTypes from "prop-types";
import { Plus, Trash2, MinusCircle } from "lucide-react";
import ImageUploadComponent from "./ImageUploadComponent";
import "../styles/Training.css";

const QuestionForm = ({
  question,
  index,
  onQuestionChange,
  onDeleteQuestion,
  onAddAnswer,
  onRemoveAnswer,
  questionImagePreview,
  explanationImagePreview,
  onQuestionImageChange,
  onExplanationImageChange,
  onRemoveQuestionImage,
  onRemoveExplanationImage
}) => {
  const handleTextChange = (field, value) => {
    onQuestionChange(index, field, value);
  };

  const handleAnswerChange = (answerIndex, value) => {
    onQuestionChange(index, "answers", {
      index: answerIndex,
      text: value
    });
  };

  const handleCorrectChange = (value) => {
    onQuestionChange(index, "correct", value);
  };

  return (
    <div className="training-question-detail">
      <div className="training-question-header">
        <div className="training-question-header-content">
          <div className="training-question-number">{index + 1}.</div>
          <input 
            type="text" 
            className="training-question-input" 
            placeholder="שאלה" 
            value={question.question || ""}
            onChange={(e) => handleTextChange("question", e.target.value)}
          />
        </div>
        <button
          onClick={() => onDeleteQuestion(index)}
          className="training-button training-button-danger"
          type="button"
        >
          <Trash2 size={16} />
          מחק שאלה
        </button>
      </div>
      
      {/* Question Image */}
      <ImageUploadComponent
        imageUrl={questionImagePreview}
        onImageUpload={(e) => onQuestionImageChange(index, e)}
        onImageRemove={() => onRemoveQuestionImage(index)}
        label="תמונה לשאלה (אופציונלי)"
        placeholderText="העלאת תמונה לשאלה"
      />

      {/* Answer Options */}
      <div className="training-question-options">
        {question.answers && question.answers.map((answer, answerIndex) => (
          <div
            key={answerIndex}
            className="training-question-option"
          >
            <input
              type="radio"
              id={`question-${index}-answer-${answerIndex}`}
              name={`question-${index}-answer`}
              value={(answerIndex + 1).toString()}
              checked={question.correct === answerIndex}
              onChange={() => handleCorrectChange(answerIndex + 1)}
              className="training-question-radio"
            />
            <input
              type="text"
              className="training-answer-input"
              placeholder={`תשובה ${answerIndex + 1}`}
              value={answer || ""}
              onChange={(e) => handleAnswerChange(answerIndex, e.target.value)}
            />
            {question.answers.length > 2 && (
              <button
                onClick={() => onRemoveAnswer(index, answerIndex)}
                className="training-button training-button-danger training-button-small"
                title="הסר תשובה"
                type="button"
              >
                <MinusCircle size={16} />
              </button>
            )}
          </div>
        ))}
        
        <button
          onClick={() => onAddAnswer(index)}
          className="training-button training-button-secondary training-button-small"
          style={{ marginTop: "8px" }}
          type="button"
        >
          <Plus size={16} />
          הוסף תשובה
        </button>
      </div>

      {/* Explanation */}
      <div className="training-explanation-section">
        <label className="training-explanation-label">
          הסבר:
        </label>
        <textarea
          className="training-explanation-input"
          value={question.explanation || ""}
          onChange={(e) => handleTextChange("explanation", e.target.value)}
          placeholder="הכנס הסבר"
        />
      </div>
      
      {/* Explanation Image */}
      <ImageUploadComponent
        imageUrl={explanationImagePreview}
        onImageUpload={(e) => onExplanationImageChange(index, e)}
        onImageRemove={() => onRemoveExplanationImage(index)}
        label="תמונה להסבר (אופציונלי)"
        placeholderText="העלאת תמונה להסבר"
      />
    </div>
  );
};

QuestionForm.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.string),
    correct: PropTypes.number,
    explanation: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  onQuestionChange: PropTypes.func.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  onAddAnswer: PropTypes.func.isRequired,
  onRemoveAnswer: PropTypes.func.isRequired,
  questionImagePreview: PropTypes.string,
  explanationImagePreview: PropTypes.string,
  onQuestionImageChange: PropTypes.func.isRequired,
  onExplanationImageChange: PropTypes.func.isRequired,
  onRemoveQuestionImage: PropTypes.func.isRequired,
  onRemoveExplanationImage: PropTypes.func.isRequired
};

export default QuestionForm;