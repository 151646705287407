import React, { useState } from 'react';
import CommonLayout from '../components/CommonLayout';
import AppConfigSettings from '../components/AppConfigSettings';
import './Settings.css';

const Settings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('appConfigs');

  // Tabs for different settings sections
  const renderTabs = () => {
    return (
      <div className="settings-tabs">
        <button 
          className={`tab-button ${activeTab === 'appConfigs' ? 'active' : ''}`}
          onClick={() => setActiveTab('appConfigs')}
        >
          הגדרות אפליקציות
        </button>
        {/* Additional tabs can be added here */}
      </div>
    );
  };

  // Content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'appConfigs':
        return <AppConfigSettings />;
      // Add cases for additional tabs here
      default:
        return <AppConfigSettings />;
    }
  };

  return (
    <CommonLayout>
      <div className="settings-container" dir="rtl">
        <h1 className="settings-title">הגדרות מערכת</h1>
        
        {renderTabs()}
        {renderContent()}
      </div>
    </CommonLayout>
  );
};

export default Settings;