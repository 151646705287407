import React, { useState } from 'react';
import { CategoryDefinition } from './types';

interface CategoryFormProps {
  onSubmit: (category: CategoryDefinition) => void;
  onCancel: () => void;
  editingCategory?: CategoryDefinition | null;
}

const CategoryForm: React.FC<CategoryFormProps> = ({ onSubmit, onCancel, editingCategory }) => {
  const [drugs, setDrugs] = useState<string[]>(editingCategory?.drugs || []);
  const [newDrug, setNewDrug] = useState('');

  const addDrug = () => {
    if (newDrug.trim()) {
      setDrugs([...drugs, newDrug.trim()]);
      setNewDrug('');
    }
  };

 const removeDrug = (index: number) => {
    setDrugs(drugs.filter((_, i) => i !== index));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const newCategory: CategoryDefinition = {
        name: formData.get('name') as string,
        drugs: drugs,
      };

    onSubmit(newCategory);
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addDrug();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="form-label">Category Name</label>
        <input
          name="name"
          className="form-control"
          required
          defaultValue={editingCategory?.name || ''}
        />
      </div>

      <div className="mb-4">
        <label className="form-label">Drugs</label>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Enter drug name"
            value={newDrug}
            onChange={(e) => setNewDrug(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={addDrug}
          >
            Add Drug
          </button>
        </div>

        <div className="list-group">
          {drugs.map((drug, index) => (
            <div key={index} className="list-group-item d-flex justify-content-between align-items-center">
              {drug}
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                onClick={() => removeDrug(index)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4 d-flex justify-content-end gap-2">
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          {editingCategory ? 'Update' : 'Add'} Category
        </button>
      </div>
    </form>
  );
};

export default CategoryForm;