import React from 'react';
import Trainings from '/src/features/trainings/Training.jsx'; // Assuming you'll save the pasted content as paste.js

const Training = () => {
  return (
    <div className="app-container">
      <Trainings />
    </div>
  );
};

export default Training;