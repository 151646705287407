import { useState, useEffect } from "react";
import API from "../../../config/axiosConfig";

/**
 * Custom hook for fetching hospitals with editor permissions
 * @returns {Object} Object containing hospitals data, loading state, error state, and isSuperAdmin flag
 */
const useHospitalsWithEditorPermission = () => {
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await API.get("/api/hospitals");
        setHospitals(response.data);
        
        const superAdminCheck = response.data.userPermissions?.includes("SUPER_ADMIN") || 
                               (response.data.length > 0 && response.data[0].userPermissions?.includes("SUPER_ADMIN"));
        
        setIsSuperAdmin(superAdminCheck);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch hospitals");
        setLoading(false);
      }
    };

    fetchHospitals();
  }, []);

  return { hospitals, loading, error, isSuperAdmin };
};

export default useHospitalsWithEditorPermission;