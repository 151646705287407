import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChevronDown, Trash2 } from "lucide-react";
import "../styles/Training.css";

const CustomSelect = ({
  label,
  value,
  options,
  placeholder,
  onSelect,
  onDelete,
  showDelete = false,
  disabled = false,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectClick = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionId) => {
    if (onSelect) {
      onSelect(optionId);
    }
    setIsOpen(false);
  };

  const handleDelete = (optionId, e) => {
    if (e) {
      e.stopPropagation();
    }
    if (onDelete) {
      onDelete(optionId);
    }
  };

  return (
    <div className={`training-select-wrapper ${className}`}>
      <div className="training-select-header">
        <label className="training-select-label">{label}</label>
      </div>
      <div className="training-select-container">
        <div
          className={`training-select-button ${isOpen ? "open" : ""} ${value ? "selected" : ""} ${disabled ? "disabled" : ""}`}
          onClick={handleSelectClick}
        >
          <span className="training-select-button-text">
            {value && options.length > 0
              ? options.find((opt) => opt.id === value)?.label || placeholder
              : placeholder}
          </span>
          <ChevronDown
            className={`training-select-chevron ${isOpen ? "open" : ""}`}
          />
        </div>
        {isOpen && options.length > 0 && (
          <div className="training-select-custom-dropdown">
            <ul className="training-select-options-list">
              {options.map((option) => {
                const IconComponent = option.icon;
                const uniqueKey = String(option.id || `option-${option.label}`);
                
                return (
                  <li
                    key={uniqueKey}
                    className={`training-select-option-item ${
                      value === option.id ? "selected" : ""
                    }`}
                    onClick={() => handleOptionClick(option.id)}
                  >
                    <div className="training-select-option-content">
                      <IconComponent className="training-select-option-icon" />
                      <span>{option.label}</span>
                    </div>
                    {showDelete && (
                      <button
                        onClick={(e) => handleDelete(option.id, e)}
                        className="training-button training-button-danger training-button-small"
                      >
                        <Trash2 size={14} />
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired
    })
  ).isRequired,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default CustomSelect;