import React, { useState, CSSProperties, useRef} from 'react';
import MedicinesTable, { MedicinesTableRef } from './MedicinesTable';
import DripMedicinesTable, { DripMedicinesTableRef } from './DripMedicinesTable';
import CategoriesTable, {CategoriesTableRef} from './CategoriesTable';
import ProtocolsTable , {ProtocolsTableRef} from './ProtocolsTable';
import { ChevronDown, ChevronUp, Download } from 'lucide-react';


const TablesPage: React.FC = () => {
  const [expandedTable, setExpandedTable] = useState<string | null>(null);

  // Refs to access child component methods or states
  const medicinesTableRef = useRef<MedicinesTableRef>(null);
  const dripMedicinesTableRef = useRef<DripMedicinesTableRef>(null);
  const categoriesTableRef = useRef<CategoriesTableRef>(null);
  const protocolsTableRef = useRef<ProtocolsTableRef>(null);

  const exportToJsonHandler = () => {
    console.log ('in export to json');
    // Collect data from all tables
    const exportData = {
      drugs: medicinesTableRef.current?.getMedicines?.() || [],
      drips: dripMedicinesTableRef.current?.getDripMedicines?.() || [],
      sections: categoriesTableRef.current?.getCategories?.() || [],
      protocols: protocolsTableRef.current?.getProtocols?.() || []
    };
    // console.log ('*** export tables', {exportData});
    // Create a blob with the JSON data
    const jsonString = JSON.stringify(exportData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    
    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    // link.download = 'medicines.json';
    link.download = `export_${new Date().toISOString().split('T')[0]}.json`;
    
    // Append to body, click, and remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleTable = (tableName: string) => {
    setExpandedTable(expandedTable === tableName ? null : tableName);
  };

  const sectionHeaderStyle: CSSProperties  = {
    color: 'white',
    backgroundColor: '#103C6E',
    padding: '10px 15px',
    fontSize: '1rem',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    borderRadius: '5px',
    gap: '10px',
    marginBottom: '7px',
    userSelect: 'none' as const,
    outline: 'none',
    transition: 'background-color 0.2s ease-in-out',
  };
  const toggleIconStyle: CSSProperties  = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'white',
  };
  const sectionNameStyle: CSSProperties  = {
    textAlign: 'right' as const,
    flexGrow: 1,
    paddingLeft: '30px',
    color: 'white'
  };
  const hiddenTableStyle: CSSProperties = {
    display: 'none'
  };

  return (
    <div className="container mt-5 overflow-auto max-h-[90vh] overflow-y-auto">
      <h1 className="text-center mb-4">ניהול תרופות</h1>
      <> 
      <button 
          className="btn btn-primary d-flex align-items-center mb-4"      
          onClick={exportToJsonHandler}
        >
          <Download size={20} className="mr-2" />
          Export to JSON
        </button>
        
        </>
      {/* Medicines Table */}
      <div className="mb-3">
        <div
          style={sectionHeaderStyle}
          onClick={() => toggleTable('medicines')}
        >
          <div style={toggleIconStyle}>
            {expandedTable === 'medicines' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          <h5 style={sectionNameStyle} className="mb-0">תרופות (Medicines)</h5>
        </div>
        <div 
          className="p-3 border border-top-0 scrollable-table"
          style={expandedTable === 'medicines' ? {} : hiddenTableStyle}>
          <MedicinesTable ref={medicinesTableRef} />
        </div>
        
      </div>

      {/* Drip Medicines Table */}
      <div className="mb-3">
        <div
          style={sectionHeaderStyle}
          onClick={() => toggleTable('drip-medicines')}
        >
          <div style={toggleIconStyle}>
            {expandedTable === 'drip-medicines' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          <h5 style={sectionNameStyle} className="mb-0">תרופות בטפטוף (Drip Medicines)</h5>
        </div>
        <div 
          className="p-3 border border-top-0 scrollable-table"
          style={expandedTable === 'drip-medicines' ? {} : hiddenTableStyle}>
          <DripMedicinesTable ref={dripMedicinesTableRef} />
        </div>
      </div>

      {/* Categories Table */}
      <div className="mb-3">
        <div
          style={sectionHeaderStyle}
          onClick={() => toggleTable('categories')}
        >
          <div style={toggleIconStyle}>
            {expandedTable === 'categories' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          <h5 style={sectionNameStyle} className="mb-0">קטגוריות (Categories)</h5>
        </div>
        <div 
          className="p-3 border border-top-0 scrollable-table"
          style={expandedTable === 'categories' ? {} : hiddenTableStyle}>
          <CategoriesTable ref={categoriesTableRef} />
        </div>
      </div>

      {/* Protocols Table */}
      <div className="mb-3">
        <div
          style={sectionHeaderStyle}
          onClick={() => toggleTable('protocols')}
        >
          <div style={toggleIconStyle}>
            {expandedTable === 'protocols' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          <h5 style={sectionNameStyle} className="mb-0">פרוטוקולים (Protocols)</h5>
        </div>
        <div 
          className="p-3 border border-top-0 scrollable-table"
          style={expandedTable === 'protocols' ? {} : hiddenTableStyle}>
          <ProtocolsTable ref={protocolsTableRef} />
        </div>
      </div>
    </div>
  );
};

export default TablesPage;