import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaArrowRight, FaInfo, FaEdit, FaDownload } from 'react-icons/fa';
import LogoImage from '../assets/logo/IconOnly_Transparent_NoBuffer.png';
import { UserContext } from '../context/UserContext';
import env from '../config/env';
import './Header.css';



const getEnvironmentConfig = () => {
  
  const configs = {
    development: {
      headerStyle: { backgroundColor: '#e6ffe6' },
      titlePrefix: '[DEV] ',
      envLabel: 'Development',
    },
    staging: {
      headerStyle: { backgroundColor: '#fff3e6' },
      titlePrefix: '[STAGING] ',
      envLabel: 'Staging',
    },
    production: {
      headerStyle: {},
      titlePrefix: '',
      envLabel: '',
    },
  };

  return configs[env.APP_ENV] || configs.production;
};

const Header = ({ creditStr, leftLogoUrl, onDownload, onShowDetails }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const isProtocolViewer = location.pathname.includes('/protocols/') && location.pathname.includes('/view');
  const envConfig = getEnvironmentConfig();
  
  const canEdit = user && (user.isSuperAdmin || user.roles.some(role => 
    role.role === 'ADMIN' || role.role === 'EDITOR'
  ));

  useEffect(() => {
    const baseTitle = 'מערכת ניהול פרוטוקולים - mashlom.me - כלי עזר לצוותי רפואה';
    document.title = `${envConfig.titlePrefix}${baseTitle}`;

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-J85BPCEQ2Y';
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-J85BPCEQ2Y');

    return () => {
      document.body.removeChild(script);
    };
  }, [envConfig.titlePrefix]);

  return (
    <nav className="navbar navbar-expand-lg header" style={envConfig.headerStyle}>
      {isProtocolViewer ? (
        <div className="header-protocol-viewer">
          <button onClick={() => navigate(-1)} className="header-button">
            <FaArrowRight />
          </button>
          <div className="button-divider"></div>
          {canEdit && (
            <>
              <button onClick={() => navigate(`/protocols/${id}/edit`)} className="header-button">
                <FaEdit />
              </button>
              <div className="button-divider"></div>
            </>
          )}          
          <button className="header-button" onClick={onShowDetails}>
            <FaInfo />
          </button>
          <div className="button-divider"></div>
          <button onClick={onDownload} className="header-button">
            <FaDownload />
          </button>
          <div className="button-divider"></div>
          {envConfig.envLabel && (
            <span className="env-label">{envConfig.envLabel}</span>
          )}
        </div>
      ) : (
        <div className="header-container">
          <div className="header-left"></div>
          <div className="header-center">
            <p className="mb-0">
              <span className="d-block" style={{ fontSize: '15px' }}>
                {envConfig.envLabel && (
                  <span className="env-indicator">{envConfig.envLabel} - כלי עזר לצוותי רפואה</span>
                )}
                {!envConfig.envLabel && (
                  <span>mashlom.me - כלי עזר לצוותי רפואה</span>
                )}              
              </span>
              <span style={{ fontSize: '10px' }}>{creditStr}</span>
            </p>
          </div>
          <div className="header-right">
            <img className="header-mashlom-logo" src={LogoImage} alt="Mashlom Logo" />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;