import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import API from '../config/axiosConfig';
import Loader from '../components/Loader';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hospitals, setHospitals] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [authToken, setAuthToken] = useState(localStorage.getItem('token'));

  const fetchUserData = useCallback(async () => {
    if (!authToken) return false;
    
    try {
      const decodedUser = jwtDecode(authToken);
      const response = await API.get('/api/users/me');
      const userData = response.data;
      setUser({ ...decodedUser, ...userData });
      return true;
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      localStorage.removeItem('token');
      setAuthToken(null);
      setUser(null);
      return false;
    }
  }, [authToken]);

  const fetchHospitals = useCallback(async () => {
    try {
      const response = await API.get('/api/hospitals');
      setHospitals(response.data);
      return true;
    } catch (error) {
      console.error('Fetching hospitals failed:', error);
      return false;
    }
  }, []);

  const fetchDepartments = useCallback(async (hospitalId) => {
    try {
      const response = await API.get(`/api/hospitals/${hospitalId}/departments`);
      setDepartments(response.data);
      return true;
    } catch (error) {
      console.error('Fetching departments failed:', error);
      return false;
    }
  }, []);

  const initializeData = useCallback(async () => {
    if (!authToken) {
      setLoading(false);
      setIsInitialized(false);
      setUser(null);
      setHospitals([]);
      return;
    }

    setLoading(true);
    try {
      const userFetched = await fetchUserData();
      if (userFetched) {
        const hospitalsFetched = await fetchHospitals();
        if (hospitalsFetched) {
          setIsInitialized(true);
        }
      }
    } catch (error) {
      console.error('Initialization failed:', error);
      localStorage.removeItem('token');
      setAuthToken(null);
    } finally {
      setLoading(false);
    }
  }, [authToken, fetchUserData, fetchHospitals]);


  useEffect(() => {
    initializeData();
  }, [initializeData]);

  const login = async (token) => {
    localStorage.setItem('token', token);
    setAuthToken(token);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setUser(null);
    setHospitals([]);
    setDepartments([]);
    setIsInitialized(false);
  };

  const refreshUserData = () => {
    fetchUserData();
  };

  const createDepartment = async (hospitalId, newDepartment) => {
    try {
      const response = await API.post(
        `/api/hospitals/${hospitalId}/departments`,
        newDepartment
      );
      setDepartments((prevDepartments) => [...prevDepartments, response.data]);
      fetchHospitals();
      return response.data;
    } catch (error) {
      console.error('Creating department failed:', error);
      throw error;
    }
  };

  if (loading) {
    return <Loader isLoading={true} />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        refreshUserData,
        hospitals,
        departments,
        createDepartment,
        fetchHospitals,
        fetchDepartments,
        isInitialized,
        authToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
