import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import CommonLayout from '../components/CommonLayout';
import { mockCases } from '../utils/mockData';
import './Cases.css';

const AddEditCase = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    tags: '',
    files: [],
    imageUrl: '',
  });
  const [originalCases, setOriginalCases] = useState(mockCases);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (id) {
      const case_ = originalCases.find((c) => c.id === id);
      if (case_) {
        setFormData({
          title: case_.title,
          description: case_.description || '',
          tags: case_.tags.join(', '),
          files: [],
          imageUrl: case_.imageUrl,
        });
        setPreviewImage(case_.imageUrl);
      }
    }
  }, [id, originalCases]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFiles = [...formData.files, ...acceptedFiles];
      setFormData((prev) => ({
        ...prev,
        files: newFiles,
      }));

      // If it's an image file, set it as preview
      const imageFile = acceptedFiles.find((file) =>
        file.type.startsWith('image/')
      );

      if (imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
          setFormData((prev) => ({
            ...prev,
            imageUrl: reader.result,
          }));
        };
        reader.readAsDataURL(imageFile);
      }
    },
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedTags = formData.tags
      .split(',')
      .map((tag) => tag.trim())
      .filter((tag) => tag);

    const updatedCase = {
      id: id || `case-${Date.now()}`,
      title: formData.title,
      description: formData.description,
      tags: formattedTags,
      imageUrl: previewImage || 'https://via.placeholder.com/300x200',
      lastModified: new Date().toISOString(),
    };

    if (id) {
      // Edit existing case
      const updatedCases = originalCases.map((case_) =>
        case_.id === id ? updatedCase : case_
      );
      setOriginalCases(updatedCases);

      // In a real application, you would save to your backend here
      // For example: await api.updateCase(id, updatedCase);
    } else {
      // Add new case
      setOriginalCases([...originalCases, updatedCase]);

      // In a real application, you would save to your backend here
      // For example: await api.createCase(updatedCase);
    }

    // Navigate back to cases list
    navigate('/cases');
  };

  const removeFile = (index) => {
    const newFiles = [...formData.files];
    newFiles.splice(index, 1);
    setFormData((prev) => ({
      ...prev,
      files: newFiles,
    }));
  };

  return (
    <CommonLayout>
      <div id="addEditCase" className="form-container">
        <h1 className="page-title">{id ? 'עריכת מקרה' : 'מקרה חדש'}</h1>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">כותרת</label>
            <input
              type="text"
              className="form-input"
              placeholder="הזן כותרת למקרה..."
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">תיאור</label>
            <textarea
              className="form-textarea"
              placeholder="הזן תיאור מפורט..."
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              rows={5}
            />
          </div>

          <div className="form-group">
            <label className="form-label">תגיות</label>
            <input
              type="text"
              className="form-input"
              placeholder="הפרד תגיות בפסיקים..."
              value={formData.tags}
              onChange={(e) =>
                setFormData({ ...formData, tags: e.target.value })
              }
            />
            <small className="form-hint">לדוגמה: לב, ריאות, דחוף</small>
          </div>

          <div className="form-group">
            <label className="form-label">תמונה ראשית וקבצים נוספים</label>
            {previewImage && (
              <div className="image-preview">
                <img src={previewImage} alt="תמונה ראשית" />
                <button
                  type="button"
                  className="remove-image"
                  onClick={() => {
                    setPreviewImage(null);
                    setFormData((prev) => ({ ...prev, imageUrl: '' }));
                  }}
                >
                  הסר תמונה
                </button>
              </div>
            )}
            <div {...getRootProps()} className="upload-zone">
              <input {...getInputProps()} />
              <FontAwesomeIcon icon={faUpload} className="upload-icon" />
              <p className="upload-text">בחר קבצים או גרור לכאן</p>
              <small>תמונות (PNG, JPG), מסמכים (PDF, DOC, DOCX)</small>
            </div>
          </div>

          {formData.files.length > 0 && (
            <div className="form-group">
              <label className="form-label">קבצים שנבחרו</label>
              <ul className="file-list">
                {formData.files.map((file, index) => (
                  <li key={index} className="file-item">
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">
                      {(file.size / 1024).toFixed(0)} KB
                    </span>
                    <button
                      type="button"
                      className="remove-file"
                      onClick={() => removeFile(index)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="form-actions">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!formData.title}
            >
              {id ? 'שמור שינויים' : 'שמור מקרה'}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate('/cases')}
            >
              ביטול
            </button>
          </div>
        </form>
      </div>
    </CommonLayout>
  );
};

export default AddEditCase;
