import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import Header from '../components/Header';
import ProtocolDetailsDialog from '../components/ProtocolDetailsDialog';
import { useProtocolFile } from '../services/protocolFileService';
import RecentProtocolsManager from '../components/RecentProtocolsManager';
import Loader from '../components/Loader';
import SimplePDFViewer from '../components/SimplePDFViewer';
import './ProtocolViewer.css';

const findHebrewNameById = (id, hospitalId, hospitals) => {
  if (!id || !hospitalId) return '';
  const hospital = hospitals.find((h) => h._id === hospitalId);
  if (!hospital) return '';
  const department = hospital.departments.find((dept) => dept._id === id);
  return department ? department.nameHebrew : '';
};

const ProtocolViewer = () => {
  const [protocol, setProtocol] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams();
  const { hospitals } = useContext(UserContext);
  const { protocolAccessed } = RecentProtocolsManager();
  const { signedUrl, loading: urlLoading } = useProtocolFile(
    protocol?.currentRevision?.url
  );

  useEffect(() => {
    if (id) {
      protocolAccessed(id);
    }
  }, [id, protocolAccessed]);

  useEffect(() => {
    const fetchProtocol = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await API.get(`/api/protocols/view/${id}`);
        setProtocol(response.data);
      } catch (err) {
        console.error('Error fetching protocol:', err);
        setError(err.response?.data?.message || 'Failed to load protocol');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchProtocol();
    }
  }, [id]);

  const handleDownload = async () => {
    try {
      const response = await fetch(signedUrl);
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `protocol-${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error('Error downloading PDF:', err);
      setError('Failed to download PDF');
    }
  };

  if (isLoading || urlLoading) {
    return <Loader isLoading={true} />;
  }

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  if (!protocol) {
    return (
      <div className="error-container">
        <p>Protocol not found</p>
      </div>
    );
  }

  return (
    <>
      <Header
        onDownload={handleDownload}
        onShowDetails={() => setShowDetails(true)}
      />
      <div className="protocol-viewer">
        {protocol.currentRevision.type === 'RICH_TEXT' ? (
          <div className="protocol-content">{protocol.body?.content}</div>
        ) : (
          <SimplePDFViewer
            file={signedUrl}
            onError={(error) => {
              console.error('Error loading PDF:', error);
              setError('Failed to load PDF document');
            }}
          />
        )}
      </div>

      {protocol && (
        <ProtocolDetailsDialog
          open={showDetails}
          onClose={() => setShowDetails(false)}
          protocol={protocol}
          department={findHebrewNameById(
            protocol.departments[0],
            protocol.hospital._id,
            hospitals
          )}
        />
      )}
    </>
  );
};

export default ProtocolViewer;
