import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Drug, SimpleDrug, CasedDrug, DrugCase } from './types';
import drugsData from './data/resus-drugs-definitions.json';
import MedicineForm from './MedicineForm';
import { faTrash, faEdit, faChevronDown, faChevronUp, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface MedicinesTableRef {
  getMedicines: () => Drug[];
}

// Type guard
function isCasedDrug(drug: Drug): drug is CasedDrug {
  return 'cases' in drug;
}

const transformDrugsData = (data: any[]): Drug[] => {
  return data.map(drug => {
    if ('cases' in drug) {
      return {
        ...drug,
        cases: drug.cases.map((caseItem: any) => ({
          predicate: caseItem.predicate,
          details: caseItem.details
        }))
      } as CasedDrug;
    }
    return drug as SimpleDrug;
  });
};

const MedicinesTable = forwardRef<MedicinesTableRef,{}>((_, ref) => {
  const [medicines, setMedicines] = useState<Drug[]>(transformDrugsData(drugsData.drugs));
  const [editingMedicine, setEditingMedicine] = useState<Drug | null>(null);
  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [expandedMedId, setExpandedMedId] = useState<string | null>(null);

  // Method to get medicines for export
  useImperativeHandle(ref, () => ({
    getMedicines: () => {
      return medicines}
  }));

  const filteredMedicines = medicines.filter((medicine) =>
    medicine.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const renderDrugInfo = (drug: Drug) => {
    if (isCasedDrug(drug)) {
      // For cased drugs, show the first case's details by default in the main row
      const firstCase = drug.cases[0];
      return {
        howToGive: firstCase.details.howToGive,
        dose_unit: firstCase.details.dose_unit,
        dose_per_kg: firstCase.details.dose_per_kg,
        concentration: firstCase.details.concentration,
        maxDose: '-', // Cases don't have maxDose in the new structure
      };
    } else {
      // For simple drugs
      return {
        howToGive: drug.howToGive,
        dose_unit: drug.dose_unit,
        dose_per_kg: drug.dose_per_kg,
        concentration: drug.concentration,
        maxDose: drug.maxDose || '-',
      };
    }
  };

  const renderCaseDetails = (cases: DrugCase[]) => {
    return cases.map((caseItem, index) => {
      const predicate = caseItem.predicate;
      const details = caseItem.details;
      
      let condition = '';
      if (predicate.type === 'weight') {
        condition = `Weight: ${predicate.minWeight || '0'}-${predicate.maxWeight || '∞'} kg`;
      } else if (predicate.type === 'age') {
        condition = `Age: ${predicate.minAge || '0'}-${predicate.maxAge || '∞'} ${predicate.unit}`;
      }

      return (
        <div key={index} className="mb-3 border-bottom pb-2">
          <strong>Case {index + 1}: {condition}</strong>
          <div>How to Give: {details.howToGive}</div>
          <div>Dose: {details.dose_per_kg} {details.dose_unit}</div>
          {details.maxDose && <div>Max Dose: {details.maxDose} {details.dose_unit}</div>}
          {details.concentration && <div>Concentration: {details.concentration}</div>}
          {details.type && <div>Type: {details.type}</div>}
          {details.comment && <div>Comment: {details.comment}</div>}
        </div>
      );
    });
  };

  const renderMedicineDetails = (med: Drug) => {
    if (isCasedDrug(med)) {
      return (
        <div>
          <h6>Multiple Cases:</h6>
          {renderCaseDetails(med.cases)}
        </div>
      );
    } else {
      const details = [];

      if (med.comment) {
        details.push(
          <div key="comment" className="mb-2">
            <strong>Comments:</strong> {med.comment}
          </div>
        );
      }
      if (med.warnText) {
        details.push(
          <div key="warnText" className="mb-2">
            <strong>Warning:</strong> {med.warnText}
          </div>
        );
      }
      if (med.minDose) {
        details.push(
          <div key="minDose" className="mb-2">
            <strong>Minimum Dose:</strong> {med.minDose} {med.minDoseUnit || med.dose_unit}
          </div>
        );
      }
      if (med.maxDose) {
        details.push(
          <div key="maxDose" className="mb-2">
            <strong>Maximum Dose:</strong> {med.maxDose} {med.dose_unit}
          </div>
        );
      }
      if (med.dose_per_kg) {
        details.push(
          <div key="minDose" className="mb-2">
            <strong>Dose per kg:</strong> {med.dose_per_kg} {med.dose_unit}
          </div>
        );
      }
      if (med.dose_range) {
        details.push(
          <div key="doseRange" className="mb-2">
            <strong>Dose Range:</strong> {med.dose_range}
          </div>
        );
      }
      if (med.concentration && med.shouldDispalyConcentration) {
        details.push(
          <div key="concentration" className="mb-2">
            <strong>concentration:</strong> {med.concentration}
          </div>
        );
      }
      return details.length > 0 ? details : <div>No additional details</div>;
    }
  };

  const handleAddOrUpdate = (medicine: Drug) => {
    if (editingMedicine) {
      setMedicines((prev) =>
        prev.map((med) => (med.id === editingMedicine.id ? medicine : med))
      );
    } else {
      setMedicines((prev) => [...prev, medicine]);
    }
    setEditingMedicine(null);
    setShowModal(false);
  };

  const handleDelete = (id: string) => {
    setMedicines((prev) => prev.filter((med) => med.id !== id));
  };

  const openModal = (medicine: Drug | null = null) => {
    setEditingMedicine(medicine);
    setShowModal(true);
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Drug Medicines</h5>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search medicines..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end mb-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => openModal(null)}
        >
          Add Medicine
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>How to Give</th>
              <th>Dose Unit</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredMedicines.length > 0 ? (
              filteredMedicines.map((medicine) => {
                const info = renderDrugInfo(medicine);
                return (
                  <React.Fragment key={medicine.id}>
                    <tr
                      onClick={() => setExpandedMedId(expandedMedId === medicine.id ? null : medicine.id)}
                      style={{ cursor: 'pointer' }}
                      className={expandedMedId === medicine.id ? 'table-active' : ''}
                    >
                      <td>{medicine.name}</td>
                      <td>{info.howToGive}</td>
                      <td>{info.dose_unit}</td>
                      <td>{medicine.type}</td>
                      <td>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              openModal(medicine);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(medicine.id);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={expandedMedId === medicine.id ? faChevronUp : faChevronDown}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </td>
                    </tr>
                    {expandedMedId === medicine.id && (
                      <tr>
                        <td colSpan={7} className="border-0 bg-light">
                          <div className="p-3">
                            {renderMedicineDetails(medicine)}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  No medicines found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingMedicine ? 'Edit Medicine' : 'Add Medicine'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MedicineForm
            onSubmit={handleAddOrUpdate}
            onCancel={() => setShowModal(false)}
            editingMedicine={editingMedicine}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default MedicinesTable;