import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, TextField, Chip, IconButton, FormControl } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import API from '../config/axiosConfig';
import './ProtocolSearchBox.css';

const ClearIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2926 14.707C11.4876 14.902 11.7436 15 11.9996 15C12.2556 15 12.5116 14.902 12.7066 14.707L13.9996 13.414L15.2926 14.707C15.4876 14.902 15.7436 15 15.9996 15C16.2556 15 16.5116 14.902 16.7066 14.707C17.0976 14.316 17.0976 13.684 16.7066 13.293L15.4136 12L16.7066 10.707C17.0976 10.316 17.0976 9.684 16.7066 9.293C16.3156 8.902 15.6836 8.902 15.2926 9.293L13.9996 10.586L12.7066 9.293C12.3156 8.902 11.6836 8.902 11.2926 9.293C10.9016 9.684 10.9016 10.316 11.2926 10.707L12.5856 12L11.2926 13.293C10.9016 13.684 10.9016 14.316 11.2926 14.707ZM19.9996 18H10.3696C10.1206 18 9.88158 17.907 9.69658 17.739L4.36858 12.894L9.74558 6.219C9.92258 6.077 10.1426 6 10.3696 6H19.9996V18ZM20.1406 4H10.3696C9.62158 4 8.90458 4.277 8.35158 4.781C8.31258 4.815 8.27758 4.854 8.24558 4.894L2.22158 12.373C1.88958 12.785 1.93558 13.384 2.32658 13.74L8.35158 19.219C8.90458 19.723 9.62158 20 10.3696 20H20.1406C21.1656 20 21.9996 19.185 21.9996 18.182V5.818C21.9996 4.815 21.1656 4 20.1406 4Z" fill="#0C3058"/>
  </svg>
);

const FilterSliderIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6C11.448 6 11 5.552 11 5C11 4.448 11.448 4 12 4C12.552 4 13 4.448 13 5C13 5.552 12.552 6 12 6ZM15 5C15 3.346 13.654 2 12 2C10.346 2 9 3.346 9 5C9 6.302 9.839 7.401 11 7.815V21C11 21.553 11.447 22 12 22C12.553 22 13 21.553 13 21V7.815C14.161 7.401 15 6.302 15 5ZM6 18C5.448 18 5 17.552 5 17C5 16.448 5.448 16 6 16C6.552 16 7 16.448 7 17C7 17.552 6.552 18 6 18ZM7 14.185V3C7 2.447 6.553 2 6 2C5.447 2 5 2.447 5 3V14.185C3.839 14.599 3 15.698 3 17C3 18.302 3.839 19.401 5 19.815V21C5 21.553 5.447 22 6 22C6.553 22 7 21.553 7 21V19.815C8.161 19.401 9 18.302 9 17C9 15.698 8.161 14.599 7 14.185ZM18 14C17.448 14 17 13.552 17 13C17 12.448 17.448 12 18 12C18.552 12 19 12.448 19 13C19 13.552 18.552 14 18 14ZM21 13C21 11.698 20.161 10.599 19 10.185V3C19 2.447 18.553 2 18 2C17.447 2 17 2.447 17 3V10.185C15.839 10.599 15 11.698 15 13C15 14.302 15.839 15.401 17 15.815V21C17 21.553 17.447 22 18 22C18.553 22 19 21.553 19 21V15.815C20.161 15.401 21 14.302 21 13Z" fill="currentColor"/>
  </svg>
);

const ProtocolSearchBox = ({ onSearch , onProtocolSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [originalUrlTag, setOriginalUrlTag] = useState(null);
  const [autocompleteData, setAutocompleteData] = useState({
    hospitals: [],
    departments: [],
    tags: [],
    protocols: []
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchAutocompleteData = async () => {
      try {
        const response = await API.get('/api/system/autocomplete');
        setAutocompleteData(response.data);
      } catch (error) {
        console.error('Error fetching autocomplete data:', error);
      }
    };
    fetchAutocompleteData();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const depParam = searchParams.get('dep');
    const tagsParam = searchParams.get('tags');
    
    let newSelectedItems = [];

    // Handle department parameter
    if (depParam) {
      const department = autocompleteData.departments.find(dep => dep._id === depParam);
      if (department) {
        newSelectedItems.push({
          category: 'departments',
          value: department,
          label: department.nameHebrew,
          key: `departments-${department._id}`
        });
      }
    }

    // Handle tags parameter
    if (tagsParam) {
      const tagsArray = tagsParam.split(',');
      const tagItems = tagsArray.map(tag => ({
        category: 'tags',
        value: tag,
        label: tag,
        key: `tag-${tag}`
      }));
      newSelectedItems = [...newSelectedItems, ...tagItems];

    // Store the original URL tag
    setOriginalUrlTag(tagsArray[0]); // Assuming single tag for now
  }

    // Only update if we have new items and autocomplete data is loaded
    if (newSelectedItems?.length > 0 && 
      autocompleteData?.departments?.length > 0 && 
      autocompleteData?.tags?.length > 0) {
    setSelectedItems(newSelectedItems);
  }
}, [location.search, autocompleteData]);

  const handleAutocompleteChange = async (event, newValue) => {
    if (newValue) {
      onProtocolSelect(newValue._id);
    }
  };

  const handleFilterSelect = (category, value) => {
    if (value) {
      if (category === 'hospitals' || category === 'departments') {
        setSelectedItems(prevItems => [
          prevItems.filter(item => item.category !== category),
        ]);
      } else if (category === 'tags' && Array.isArray(value)) {
        const existingNonTags = selectedItems.filter(item => item.category !== 'tags');
        const newTags = value.map(tag => ({
          category: 'tags',
          value: tag,
          label: tag,
          key: `tag-${tag}`
        }));
        newItems = [...existingNonTags, ...newTags];
      }
      
      setSelectedItems(newItems);
      document.activeElement.blur();
    }
  };

  const handleDelete = (itemToDelete) => {
    const newItems = selectedItems.filter(item => item !== itemToDelete);
    setSelectedItems(newItems);

    // Only update URL if we're removing the original URL tag
    if (itemToDelete.category === 'tags' && itemToDelete.value === originalUrlTag) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('tags');
      
      // Keep other parameters (like dep) intact
      const newSearch = searchParams.toString();
      const newPath = newSearch ? `${location.pathname}?${newSearch}` : location.pathname;
      navigate(newPath, { replace: true });
      setOriginalUrlTag(null);
    }
  };

  const handleClearAll = () => {
    setSelectedItems([]);
    
    // Only update URL if we had an original URL tag
    if (originalUrlTag) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('tags');
      
      // Keep other parameters intact
      const newSearch = searchParams.toString();
      const newPath = newSearch ? `${location.pathname}?${newSearch}` : location.pathname;
      navigate(newPath, { replace: true });
      setOriginalUrlTag(null);
    }
  };


  const debouncedSearch = useMemo(
    () => debounce((filters) => {
      onSearch(filters);
    }, 300),
    [onSearch]
  );

  useEffect(() => {
    const filters = {
      hospital: selectedItems.find(item => item.category === 'hospitals')?.value._id,
      department: selectedItems.find(item => item.category === 'departments')?.value._id,
      tags: selectedItems.filter(item => item.category === 'tags').map(item => item.value),
      searchTerm
    };
    debouncedSearch(filters);

    return () => {
      debouncedSearch.cancel();
    };
  }, [selectedItems, searchTerm, debouncedSearch]);

  const getChipLabel = (option) => {
    if (option.category === 'tags') {
      return option.value;
    } else if (option.value && typeof option.value === 'object') {
      return option.value.nameHebrew || option.label || 'Unknown';
    } else {
      return option.label || 'Unknown';
    }
  };

  const getSelectedValuesByCategory = (category) => {
    if (category === 'tags') {
      return selectedItems
        .filter(item => item.category === 'tags')
        .map(item => item.value);
    }
    return selectedItems.find(item => item.category === category)?.value || null;
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div className="protocol-search-box">
      <div className="search-container">
      <Autocomplete
          className="search-input"
          options={autocompleteData.protocols}
          getOptionLabel={(option) => option.name || ''}
          noOptionsText="לא נמצאו תוצאות"
          freeSolo // Add this to allow custom input values
          inputValue={searchTerm} // Use searchTerm directly
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="חיפוש פרוטוקולים..."
              variant="outlined"
              fullWidth
              value={searchTerm} // Add this to control the input value
              onChange={(e) => setSearchTerm(e.target.value)} // Add this to handle direct input changes
              InputProps={{
                ...params.InputProps,
                endAdornment: null
              }}
            />
          )}
          renderOption={(props, option, index) => (
            <li {...props} key={`protocol-${option._id}-${index}`}>
              {option.name}
            </li>
          )}
          onChange={handleAutocompleteChange}
          value={null}
          disablePortal
          openOnFocus={false}
        />
        <IconButton 
          className={`filter-button ${isFilterOpen ? 'active' : ''}`}
          onClick={toggleFilter}
        >
          <FilterSliderIcon />
        </IconButton>
      </div>

      <div className={`filter-panel-container ${isFilterOpen ? 'open' : ''}`}>
        <div className={`filter-panel ${isFilterOpen ? 'open' : ''}`}>
          <div className="filters-grid">
            {autocompleteData.hospitals.length > 1 && (
              <FormControl className="filter-field">
                <label className="filter-label">בית חולים</label>
                <Autocomplete
                  value={getSelectedValuesByCategory('hospitals')}
                  onChange={(_, newValue) => handleFilterSelect('hospitals', newValue)}
                  options={autocompleteData.hospitals}
                  noOptionsText="לא נמצאו תוצאות"
                  getOptionLabel={(option) => option.nameHebrew}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="בחר בית חולים" />
                  )}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  renderOption={(props, option, index) => (
                    <li {...props} key={`hospital-${option._id}-${index}`}>
                      {option.nameHebrew}
                    </li>
                  )}
                  disableClearable
                />
              </FormControl>
            )}

            <FormControl className="filter-field">
              <label className="filter-label">מחלקה</label>
              <Autocomplete
                value={getSelectedValuesByCategory('departments')}
                onChange={(_, newValue) => handleFilterSelect('departments', newValue)}
                options={autocompleteData.departments}
                noOptionsText="לא נמצאו תוצאות"
                getOptionLabel={(option) => option.nameHebrew}
                renderInput={(params) => (
                  <TextField {...params} placeholder="בחר מחלקה" />
                )}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderOption={(props, option, index) => (
                  <li {...props} key={`department-${option._id}-${index}`}>
                    {option.nameHebrew}
                  </li>
                )}
                disableClearable
              />
            </FormControl>

            <FormControl className="filter-field">
              <label className="filter-label">תגיות</label>
              <Autocomplete
                multiple
                value={getSelectedValuesByCategory('tags')}
                onChange={(_, newValue) => handleFilterSelect('tags', newValue)}
                options={autocompleteData.tags}
                noOptionsText="לא נמצאו תוצאות"
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    placeholder="בחר תגיות"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: null
                    }}
                  />
                )}
                renderOption={(props, option, index) => (
                  <li {...props} key={`tag-${option}-${index}`}>
                    {option}
                  </li>
                )}
                renderTags={() => null}
                disableClearable
                forcePopupIcon={true}
                filterSelectedOptions={false}
                clearOnBlur={false}
              />
            </FormControl>
          </div>
        </div>
      </div>

      {selectedItems.length > 0 && (
        <div className="selected-chips">
        {selectedItems.map((option) => (
            <Chip
              key={option.key}
              label={getChipLabel(option)}
              onDelete={() => handleDelete(option)}
              className="chip"
            />
          ))}
          <button 
            className="clear-filters-button" 
            onClick={handleClearAll}
          >            
            <ClearIcon />
            נקה סינון
          </button>
        </div>
      )}
    </div>
  );
};

export default ProtocolSearchBox;
