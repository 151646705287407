import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import CommonLayout from '../components/CommonLayout';
import ProtocolSearchBox from '../components/ProtocolSearchBox';
import RecentProtocolsManager from '../components/RecentProtocolsManager';
import { protocolFileService } from '../services/protocolFileService';
import Loader from '../components/Loader';
import './Protocols.css';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#1FB5A3',
          color: 'white',
          margin: '0 4px 4px 0',
        },
      },
    },
  },
});

const isMobileDevice = () => {
  return (
    typeof window !== 'undefined' &&
    (window.innerWidth <= 768 || 
     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  );
};

function Protocols() {
  const [protocols, setProtocols] = useState([]);
  const [filteredProtocols, setFilteredProtocols] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteProtocol, setDeleteProtocol] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add this state
  const [currentFilters, setCurrentFilters] = useState({ department: null, tags: [] });
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { getLastRecentlyUsedProtocols } = RecentProtocolsManager();
  
    useEffect(() => {
      const fetchProtocols = async () => {
        setIsLoading(true);
        try {
          const searchParams = new URLSearchParams(location.search);
          const depParam = searchParams.get('dep');
          const tagParam = searchParams.get('tags');
          const tagsArray = tagParam ? tagParam.split(',') : [];
  
          // Set initial filters
          setCurrentFilters({
            department: depParam || null,
            tags: tagsArray
          });
    
          let url = '/api/protocols';
          const params = [];
          if (depParam) params.push(`departmentId=${depParam}`);
          if (tagsArray.length > 0) params.push(`tags=${tagsArray.join(',')}`);
          if (params.length > 0) url += `?${params.join('&')}`;
    
          const res = await API.get(url);
          setProtocols(res.data);
          
          // Use the sortAndFilterProtocols with initial filters
          sortAndFilterProtocols(res.data, { department: depParam, tags: tagsArray });
        } catch (error) {
          console.error('Error fetching protocols:', error);
        } finally {
          setIsLoading(false);
        }
      };
    
      fetchProtocols();
    }, [location]);
  
    const sortAndFilterProtocols = (protocolsToSort, filters = {}) => {
      // Merge incoming filters with current filters if no new filters provided
      const finalFilters = {
        department: filters.department ?? currentFilters.department,
        tags: filters.tags ?? currentFilters.tags
      };
  
      // Update current filters
      setCurrentFilters(finalFilters);
  
      const recentlyUsedProtocols = getLastRecentlyUsedProtocols();
  
      const filtered = protocolsToSort.filter(protocol => {
        const matchesDepartment = !finalFilters.department || 
          protocol.departments.includes(finalFilters.department);
        
        const matchesTags = !finalFilters.tags.length || 
          finalFilters.tags.every(tag => 
            protocol.tags && protocol.tags.some(protocolTag => 
              protocolTag.toLowerCase() === tag.toLowerCase()
            )
          );
  
        return matchesDepartment && matchesTags;
      });
  
      const sorted = filtered.sort((a, b) => {
        const aIndex = recentlyUsedProtocols.findIndex(p => p.id === a._id);
        const bIndex = recentlyUsedProtocols.findIndex(p => p.id === b._id);
        
        if (aIndex === -1 && bIndex === -1) return 0;
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex - bIndex;
      });
  
      setFilteredProtocols(sorted);
    };
  
    const handleSearch = (filters) => {
      // When handling new search, merge with URL filters
      const searchParams = new URLSearchParams(location.search);
      const urlDepParam = searchParams.get('dep');
      const urlTagParam = searchParams.get('tags');
      const urlTagsArray = urlTagParam ? urlTagParam.split(',') : [];
  
      const mergedFilters = {
        department: filters.department || urlDepParam || null,
        tags: filters.tags?.length ? filters.tags : urlTagsArray
      };
  
      sortAndFilterProtocols(protocols, mergedFilters);
    };
  

  const handleProtocolSelect = (protocolId) => {
    const protocol = protocols.find(p => p._id === protocolId);
    if (isMobileDevice() && protocol?.currentRevision?.type === 'AZURE_BLOB') {
      try {        
        const baseUrl = protocol.currentRevision.url || protocol.fileUrl;
        const signedUrl = protocolFileService.getSignedUrlSync(baseUrl);
        window.open(signedUrl, '_blank');
      } catch (error) {
        console.error('Error getting signed URL:', error);
        navigate(`/protocols/${protocolId}/view`);
      }
    } else {
      navigate(`/protocols/${protocolId}/view`);
    }
  };

  const handleView = (id) => {
    handleProtocolSelect(id);
  };

  const handleEdit = (e, id) => {
    e.stopPropagation();
    navigate(`/protocols/${id}/edit`);
  };

  const openDeleteConfirm = (e, protocol) => {
    e.stopPropagation();
    setDeleteProtocol(protocol);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    setIsLoading(true); // Start loading for delete operation
    try {
      await API.delete(`/api/protocols/${deleteProtocol._id}`);
      setProtocols(protocols.filter(p => p._id !== deleteProtocol._id));
      setFilteredProtocols(filteredProtocols.filter(p => p._id !== deleteProtocol._id));
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error('Deletion failed:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleAddNewProtocol = () => {
    const searchParams = new URLSearchParams(location.search);
    const depParam = searchParams.get('dep');
    
    if (depParam) {
      navigate(`/protocols/new?dep=${depParam}`);
    } else {
      navigate('/protocols/new');
    }
  };
  
  const canCreateProtocol = () => {
    return user && (user.isSuperAdmin || user.roles.some(
      role => ['EDITOR', 'ADMIN'].includes(role.role)
    ));
  };

  const canEditProtocol = (protocol) => {
    return user && (user.isSuperAdmin || user.roles.some(
      role => role.hospital === protocol.hospital && ['EDITOR', 'ADMIN'].includes(role.role)
    ));
  };

  return (
    <CommonLayout>
      <div className="protocols-container" style={{ position: 'relative', minHeight: '50dvh'}}>
        <Loader isLoading={isLoading} />
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <ProtocolSearchBox onSearch={handleSearch} onProtocolSelect={handleProtocolSelect} />

            <div className="protocols-list">
              {filteredProtocols.map(protocol => (
                <div
                  key={protocol._id}
                  className="protocol-item"
                  onClick={(e) => handleView(protocol._id)}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: 'pointer', WebkitTouchCallout: 'none' }}
                >
                  <div className="protocol-info">
                    <span className="protocol-name">{protocol.name}</span>
                  </div>
                  {canEditProtocol(protocol) && (
                    <div 
                      className="protocol-actions"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={(e) => handleEdit(e, protocol._id)}
                        className="action-icon"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={(e) => openDeleteConfirm(e, protocol)}
                        className="action-icon"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
              <DialogTitle>האם אתה בטוח שברצונך למחוק את הפרוטוקול {deleteProtocol?.name}?</DialogTitle>
              <DialogActions>
                <Button onClick={() => setDeleteConfirmOpen(false)}>ביטול</Button>
                <Button onClick={handleDelete} color="error">
                  מחק
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </CacheProvider>

        {canCreateProtocol() && <button className="floating-add-btn" onClick={handleAddNewProtocol}>
          <FontAwesomeIcon icon={faPlus} />
        </button>}        
      </div>
    </CommonLayout>
  );
}

export default Protocols;
