/**
 * Clears all image states by revoking object URLs and resetting state objects
 * @param {Object} questionImagePreviews - Object containing question image previews
 * @param {Object} explanationImagePreviews - Object containing explanation image previews
 * @param {Function} setQuestionImages - State setter for question images
 * @param {Function} setExplanationImages - State setter for explanation images
 * @param {Function} setQuestionImagePreviews - State setter for question image previews
 * @param {Function} setExplanationImagePreviews - State setter for explanation image previews
 */
export const clearAllImageStates = (
    questionImagePreviews,
    explanationImagePreviews,
    setQuestionImages,
    setExplanationImages,
    setQuestionImagePreviews,
    setExplanationImagePreviews
  ) => {
    // Clear files
    setQuestionImages({});
    setExplanationImages({});
    
    // Release object URLs to avoid memory leaks
    Object.values(questionImagePreviews).forEach(url => {
      if (url) URL.revokeObjectURL(url);
    });
    Object.values(explanationImagePreviews).forEach(url => {
      if (url) URL.revokeObjectURL(url);
    });
    
    // Clear previews
    setQuestionImagePreviews({});
    setExplanationImagePreviews({});
  };
  
  /**
   * Removes a question image for a specific question index
   * @param {number} questionIndex - Index of the question
   * @param {Object} questionImagePreviews - Object containing question image previews
   * @param {Function} setQuestionImages - State setter for question images
   * @param {Function} setQuestionImagePreviews - State setter for question image previews
   * @param {string} selectedTestId - ID of the selected test
   * @param {Array} tests - Array of tests
   * @param {Function} setTests - State setter for tests
   */
  export const removeQuestionImage = (
    questionIndex,
    questionImagePreviews,
    setQuestionImages,
    setQuestionImagePreviews,
    selectedTestId,
    tests,
    setTests
  ) => {
    // Remove file and preview for specific question
    setQuestionImages(prev => {
      const newImages = {...prev};
      delete newImages[questionIndex];
      return newImages;
    });
    
    // Release object URL to avoid memory leaks
    if (questionImagePreviews[questionIndex]) {
      URL.revokeObjectURL(questionImagePreviews[questionIndex]);
      
      setQuestionImagePreviews(prev => {
        const newPreviews = {...prev};
        delete newPreviews[questionIndex];
        return newPreviews;
      });
    }
    
    // Also clear image reference in selected test if it exists
    if (selectedTestId) {
      const selectedTest = tests.find((test) => test.id === selectedTestId);
      if (selectedTest && selectedTest.questions && selectedTest.questions.length > questionIndex) {
        const updatedQuestions = [...selectedTest.questions];
        if (updatedQuestions[questionIndex]) {
          // Instead of just deleting the property, replace it with a marker for deletion
          updatedQuestions[questionIndex].questionImage = { __deleted: true };
        
          const updatedTests = tests.map((test) =>
            test.id === selectedTestId
              ? { ...test, questions: updatedQuestions }
              : test
          );
          
          setTests(updatedTests);
        }
      }
    }
  };
  
  /**
   * Removes an explanation image for a specific question index
   * @param {number} questionIndex - Index of the question
   * @param {Object} explanationImagePreviews - Object containing explanation image previews
   * @param {Function} setExplanationImages - State setter for explanation images
   * @param {Function} setExplanationImagePreviews - State setter for explanation image previews
   * @param {string} selectedTestId - ID of the selected test
   * @param {Array} tests - Array of tests
   * @param {Function} setTests - State setter for tests
   */
  export const removeExplanationImage = (
    questionIndex,
    explanationImagePreviews,
    setExplanationImages,
    setExplanationImagePreviews,
    selectedTestId, 
    tests,
    setTests
  ) => {
    // Remove file and preview for specific question
    setExplanationImages(prev => {
      const newImages = {...prev};
      delete newImages[questionIndex];
      return newImages;
    });
    
    // Release object URL to avoid memory leaks
    if (explanationImagePreviews[questionIndex]) {
      URL.revokeObjectURL(explanationImagePreviews[questionIndex]);
      
      setExplanationImagePreviews(prev => {
        const newPreviews = {...prev};
        delete newPreviews[questionIndex];
        return newPreviews;
      });
    }
    
    // Also clear image reference in selected test if it exists
    if (selectedTestId) {
      const selectedTest = tests.find((test) => test.id === selectedTestId);
      if (selectedTest && selectedTest.questions && selectedTest.questions.length > questionIndex) {
        const updatedQuestions = [...selectedTest.questions];
        if (updatedQuestions[questionIndex]) {
          // Instead of just deleting the property, replace it with a marker for deletion
          updatedQuestions[questionIndex].explanationImage = { __deleted: true };
          
          const updatedTests = tests.map((test) =>
            test.id === selectedTestId
              ? { ...test, questions: updatedQuestions }
              : test
          );
          
          setTests(updatedTests);
        }
      }
    }
  };
  
  /**
   * Gets the client view URL for the selected test
   * @param {string} selectedHospitalId - ID of the selected hospital
   * @param {string} selectedTopicId - ID of the selected topic
   * @param {string} selectedTestId - ID of the selected test
   * @returns {string|null} URL for client view, or null if requirements are not met
   */
  export const getClientViewUrl = (selectedHospitalId, selectedTopicId, selectedTestId) => {
    if (!selectedHospitalId || !selectedTopicId) {
      return null;
    }
    
    const hostname = window.location.hostname;
    let baseUrl;
    
    if (hostname.includes('localhost')) {
      baseUrl = 'http://localhost:5173';
    } else if (hostname.includes('stg')) {
      baseUrl = 'https://trainings.mashlom.me/staging';
    } else {
      baseUrl = 'https://trainings.mashlom.me/staging'; // should be without staging, it is here just to not change the client side in production so roded will only see the unupdated 1
    }
    
    const topicForUrl = encodeURIComponent(selectedTopicId);
    let url = `${baseUrl}/#/training-topic/${topicForUrl}`;
    
    if (selectedTestId) {
      url += `/test/${encodeURIComponent(selectedTestId)}`;
    }
    
    return url;
  };