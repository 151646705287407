import React, { useState, forwardRef, useImperativeHandle} from 'react';
import { Drip, SimpleDrip, CasedDrip, DripCase, DoseRange } from './types';
import drugsData from './data/resus-drugs-definitions.json';
import DripMedicineForm from './DripMedicineForm';
import { faTrash, faEdit, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface DripMedicinesTableRef {
  getDripMedicines: () => Drip[];
}

// Type guard
function isCasedDrip(drip: Drip): drip is CasedDrip {
  return 'cases' in drip;
}

// Transform function to ensure type safety
const transformDripsData = (data: any[]): Drip[] => {
  return data.map(drip => {
    if ('cases' in drip) {
      return {
        id: drip.id,
        name: drip.name,
        cases: drip.cases.map((caseItem: any) => ({
          predicate: caseItem.predicate,
          details: caseItem.details
        }))
      } as CasedDrip;
    }
    return drip as SimpleDrip;
  });
};

const DripMedicinesTable = forwardRef<DripMedicinesTableRef,{}>((_, ref) => {
  const [medicines, setMedicines] = useState<Drip[]>(transformDripsData(drugsData.drips));
  const [editingMedicine, setEditingMedicine] = useState<Drip | null>(null);
  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [expandedMedId, setExpandedMedId] = useState<string | null>(null);

  // Method to get medicines for export
  useImperativeHandle(ref, () => ({
    getDripMedicines: () => {
      return medicines}
  }));

  const filteredMedicines = medicines.filter((medicine) =>
    medicine.name.toLowerCase().includes(searchText.toLowerCase())
  );
  function parseRangeString(input: string): DoseRange {
    const match = input.match(/^(\d+(\.\d+)?)\s*(?:-(\d+(\.\d+)?)\s*)?(.+)?$/);

    if (!match) {
        throw new Error("Invalid input format");
    }

    const min = parseFloat(match[1]); // First number
    const max = match[3] ? parseFloat(match[3]) : min; // Second number (if exists)
    const units = match[5] ? match[5].trim() : ""; // Everything after the number(s)
    return { min, max, units };
  }
  const renderDripInfo = (drip: Drip) => {
    if (isCasedDrip(drip)) {
      const firstCase = drip.cases[0].details;
      return {
        dose_unit: firstCase.dose_unit,
        calc_type: firstCase.calc_type,
        allowed_dose_range: firstCase.allowed_dose_range
          ? parseRangeString(firstCase.allowed_dose_range) 
          : { min: 0, max: 0, units: '-' }, // Default if no range
        default_dilution_volume_ml: firstCase.default_dilution_volume_ml
      };
    } else {
      return {
        dose_unit: drip.dose_unit,
        calc_type: drip.calc_type,
        allowed_dose_range: drip.allowed_dose_range
          ? parseRangeString(drip.allowed_dose_range) 
          : { min: 0, max: 0, units: '-' }, // Default if no range
        default_dilution_volume_ml: drip.default_dilution_volume_ml
      };
    }
  };

  const renderWeightBasedCase = (caseItem: DripCase) => {
    const { predicate, details } = caseItem;
    let parsedRange = { min: 0, max: 0, units: '-' };
    if (details.allowed_dose_range){parsedRange = parseRangeString(details.allowed_dose_range)};
    return (
      <div className="mb-3 border-bottom pb-2">
        <strong>Weight Range: {predicate.minWeight || '0'} - {predicate.maxWeight || '∞'} kg</strong>
        <div>Target Volume: {details.target_volume_ml_per_hour} ml/hour</div>
        {details.dose_per_kg_per_min && 
          <div>Dose per kg per min: {details.dose_per_kg_per_min} {details.dose_unit}</div>}
        {details.dose_per_kg_per_hour && 
          <div>Dose per kg per hour: {details.dose_per_kg_per_hour} {details.dose_unit}</div>}
        {parsedRange.min !== 0 && parsedRange.max !== 0  && 
          <div>Allowed Dose Range: {parsedRange.min}-{parsedRange.max} {parsedRange.units} </div>}
        {details.default_dilution_volume_ml && 
          <div>נפח למהילה (מ"ל): {details.default_dilution_volume_ml} {details.default_dilution_volume_unit}</div>}
      </div>
    );
  };

  const renderMedicineDetails = (medicine: Drip) => {
    if (isCasedDrip(medicine)) {
      return (
        <div>
          <h6>Weight-based Cases:</h6>
          {medicine.cases.map((caseItem, index) => (
            <div key={index}>
              {renderWeightBasedCase(caseItem)}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          {medicine.dose_per_kg_per_min && (
            <div className="mb-2">
              <strong>Dose per kg per min:</strong> {medicine.dose_per_kg_per_min} {medicine.dose_unit}
            </div>
          )}
          {medicine.dose_per_kg_per_hour && (
            <div className="mb-2">
              <strong>Dose per kg per hour:</strong> {medicine.dose_per_kg_per_hour} {medicine.dose_unit}
            </div>
          )}
          {medicine.max_dose_per_hour && (
            <div className="mb-2">
              <strong>Max dose per hour:</strong> {medicine.max_dose_per_hour} {medicine.dose_unit}
            </div>
          )}
          {medicine.target_volume_ml_per_hour && (
            <div className="mb-2">
              <strong>Target Volume:</strong> {medicine.target_volume_ml_per_hour} ml/hour
            </div>
          )}
          {medicine.allowed_dose_range && (
            <div className="mb-2">
              <strong>Allowed Dose Range:</strong> {medicine.allowed_dose_range}
            </div>
          )}
          {medicine.existing_dilution_concentration && (
            <div className="mb-2">
              <strong>Existing Dilution Concentration:</strong> {medicine.existing_dilution_concentration} {medicine.existing_dilution_concentration_dose_unit}
            </div>
          )}
          {medicine.default_dilution_volume_ml && (
            <div className="mb-2">
              <strong>מינון למהילה (מ"ל):</strong> {medicine.default_dilution_volume_ml}
            </div>
          )}
        </div>
      );
    }
  };

  const handleAddOrUpdate = (medicine: Drip) => {
    if (editingMedicine) {
      setMedicines((prev) =>
        prev.map((med) => (med.id === editingMedicine.id ? medicine : med))
      );
    } else {
      setMedicines((prev) => [...prev, medicine]);
    }
    setEditingMedicine(null);
    setShowModal(false);
  };

  const handleDelete = (id: string) => {
    setMedicines((prev) => prev.filter((med) => med.id !== id));
  };

  const openModal = (medicine: Drip | null = null) => {
    setEditingMedicine(medicine);
    setShowModal(true);
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Drip Medicines</h5>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search medicines..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end mb-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => openModal(null)}
        >
          Add Medicine
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>שם</th>
              <th>יחידות מינון</th>
              <th>שיטת חישוב</th>
              <th>פעולות</th>
            </tr>
          </thead>
          <tbody>
            {filteredMedicines.length > 0 ? (
              filteredMedicines.map((medicine) => {
                const info = renderDripInfo(medicine);
                return (
                  <React.Fragment key={medicine.id}>
                    <tr
                      onClick={() => setExpandedMedId(expandedMedId === medicine.id ? null : medicine.id)}
                      style={{ cursor: 'pointer' }}
                      className={expandedMedId === medicine.id ? 'table-active' : ''}
                    >
                      <td>{medicine.name}</td>
                      <td>{info.dose_unit}</td>
                      <td>{info.calc_type}</td>
                      <td>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              openModal(medicine);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(medicine.id);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={expandedMedId === medicine.id ? faChevronUp : faChevronDown}
                          />
                        </div>
                      </td>
                    </tr>
                    {expandedMedId === medicine.id && (
                      <tr>
                        <td colSpan={6} className="border-0 bg-light">
                          <div className="p-3">
                            {renderMedicineDetails(medicine)}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No medicines found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingMedicine ? 'Edit Medicine' : 'Add Medicine'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DripMedicineForm
            onSubmit={handleAddOrUpdate}
            onCancel={() => setShowModal(false)}
            editingDrip={editingMedicine}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default DripMedicinesTable;