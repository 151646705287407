import React, { useState } from "react";
import PropTypes from "prop-types";
import { Upload, XCircle } from "lucide-react";
import "../styles/Training.css";

const ImageUploadComponent = ({
  imageUrl,
  onImageUpload,
  onImageRemove,
  label = "תמונה",
  placeholderText = "העלאת תמונה",
  disabled = false
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      onImageUpload(e);
    }
  };

  const handleRemoveImage = () => {
    onImageRemove();
  };

  return (
    <div className="training-image-upload-section">
      <label className="training-image-upload-label">{label}:</label>
      <div className="training-image-upload-container">
        {imageUrl ? (
          <div 
            className="training-image-preview" 
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <img 
              src={imageUrl} 
              alt={label} 
              className="training-preview-image" 
              style={{ opacity: isHovering ? 0.7 : 1 }}
            />
            <button 
              type="button" 
              className="training-button training-button-danger training-button-small training-remove-image"
              onClick={handleRemoveImage}
              style={{ opacity: isHovering ? 1 : 0.8 }}
            >
              <XCircle size={16} />
            </button>
          </div>
        ) : (
          <label className={`training-image-upload-button ${disabled ? 'disabled' : ''}`}>
            <Upload size={16} />
            {placeholderText}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              disabled={disabled}
              style={{ display: 'none' }}
            />
          </label>
        )}
      </div>
    </div>
  );
};

ImageUploadComponent.propTypes = {
  imageUrl: PropTypes.string,
  onImageUpload: PropTypes.func.isRequired,
  onImageRemove: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  disabled: PropTypes.bool
};

export default ImageUploadComponent;