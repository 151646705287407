// TBD - add/remove category, add/remove/edit drug in category (or click to switch to the drug entry (and edit from there))


import React, { useState, forwardRef, useImperativeHandle } from 'react';
import drugsData from './data/resus-drugs-definitions.json';
import {CategoryDefinition} from './types';
import CategoryForm from './CategoryForm';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface CategoriesTableRef {
  getCategories: () => CategoryDefinition[];
}

const CategoriesTable = forwardRef<CategoriesTableRef, {}>((_,ref) => {
  const [searchText, setSearchText] = useState('');
  const [expandedMedId, setExpandedMedId] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState<CategoryDefinition | null>(null);
  const [categories, setCategories] = useState<CategoryDefinition[]>(drugsData.sections);

  useImperativeHandle(ref, () => ({
    getCategories: () => {
      return categories}
  }));

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSubmit = (category: CategoryDefinition) => {
    if (editingCategory) {
      // Update existing category
      setCategories(categories.map(cat => 
        cat.name === editingCategory.name ? category : cat
      ));
    } else {
      // Add new category
      setCategories([...categories, category]);
    }
    handleCloseModal();
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setEditingCategory(null);
  };

  const handleEdit = (category: CategoryDefinition) => {
    setEditingCategory(category);
    setShowModal(true);
  };
  const handleDelete = (categoryName: string) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      setCategories(categories.filter(cat => cat.name !== categoryName));
    }
  };


  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-3">      <input
        type="text"
        className="form-control mb-3"
        placeholder="Search by name..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
        <button
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
        >
        הוספת קטגוריה
        </button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editingCategory ? 'Edit Category' : 'Add New Category'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CategoryForm
            onSubmit={handleSubmit}
            onCancel={handleCloseModal}
            editingCategory={editingCategory}
          />
        </Modal.Body>
      </Modal>

      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>קטגוריות</th>
              <th>פעולות</th>
            </tr>
          </thead>
          <tbody>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((category) => (
                <React.Fragment key={category.name}>
                  <tr 
                    onClick={() => setExpandedMedId(expandedMedId === category.name ? null : category.name)}
                    style={{ cursor: 'pointer' }}
                    // className={expandedMedId === category.name ? 'table-active' : ''}
                    >
                    <td>{category.name}</td>
                    <td>
                      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <FontAwesomeIcon 
                          icon={faEdit} 
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleEdit(category)} // Open modal for editing
                          // onClick={() => setEditingMedicine(medicine)} // Open form with selected medicine
                        />
                        <FontAwesomeIcon 
                          icon={faTrash} 
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleDelete(category.name)} // Delete the selected medicine
                        />
                      </div>
                </td>
                  </tr>
                  {expandedMedId === category.name && (
                    <tr>
                      <td colSpan={5} className="border-0 bg-light">
                        <div className="p-3">
                        <div className="list-group">
                          {category.drugs.map((drug, index) => (
                            <div key={index} className="list-group-item">
                              {drug}
                            </div>
                          ))}
                        </div>                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                 No medicines found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default CategoriesTable;
