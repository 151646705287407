import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { UserContext } from '../context/UserContext';
import CommonLayout from '../components/CommonLayout';
import API from '../config/axiosConfig';
import './Welcome.css';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
});

function Welcome() {
  const { user } = useContext(UserContext);

  
  const isAdmin = user?.isSuperAdmin || user?.roles?.some(role => role.role === 'ADMIN');
  const isEditorOrAdmin = user?.isSuperAdmin || user?.roles?.some(role => ['EDITOR', 'ADMIN'].includes(role.role));
  
  const getEditorsAndAdminsNames = async () => {
    try {
      const hospitalId = user.roles[0].hospital;
      const response = await API.get(`/api/users`, {
        params: {
          hospitalId: hospitalId,
        }   
      });
      const users = response.data.filter(user => user.roles.some(role => ['EDITOR', 'ADMIN'].includes(role.role)));

      return users.map(user => user.name).join(', ');
    } catch (error) {
      console.error('Error fetching editors and admins:', error);
      return 'מנהל המערכת';
    }
  };
  
  const getAdminsName = async () => {
    try {
      const hospitalId = user.roles[0].hospital;
      const response = await API.get(`/api/users`, {
        params: {
          hospitalId: hospitalId,
        }
      }
      );
      const admins = response.data.filter(user => user.roles.some(role => role.role === 'ADMIN'));
      return admins.map(user => user.name).join(', ');
    } catch (error) {
      console.error('Error fetching admin:', error);
      return 'מנהל המערכת';
    }
  };

  const [adminName, setAdminName] = useState('');
  const [editorsAndAdmins, setEditorsAndAdminsNames] = useState('');

  useEffect(() => {
    const fetchNames = async () => {
      if (!isAdmin) {
        const admins = await getAdminsName();
        const editors = await getEditorsAndAdminsNames();
        setAdminName(admins);
        setEditorsAndAdminsNames(editors);
      }
    };
    
    fetchNames();
  }, [isAdmin]);
    
  // Common content that appears for everyone
  const commonContent = (
    <>
      <p>
        ברוכים הבאים למערכת ניהול הפרוטוקולים ומערך הרפואה בבתי החולים מבית היוצר של משלום. אנחנו עושים הכל על מנת לאפשר לכם לשפר את הטיפול הרפואי שאתם מעניקים. בכל שאלה - מוזמנים ליצור קשר במייל {' '}
        <a href="mailto:mashlom.me@gmail.com">mashlom.me@gmail.com</a>
      </p>
      <p>
        בכניסה הראשונה שלך למערכת, ומעת לעת - מומלץ{' '}
        <Link to="/profile" style={{ color: '#1FB5A3', textDecoration: 'underline' }}>
          לשנות את הסיסמה בדף הפרופיל
        </Link>
      </p>
    </>
  );

  return (
    <CommonLayout>
      <div className="welcome-container" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <div className="welcome-content" 
            style={{ backgroundColor: 'white', padding: '24px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <h1 style={{ color: isAdmin ? '#406286' : '#1FB5A3', marginBottom: '24px' }}>
                ברוכים הבאים למערכת MY
              </h1>

              <div className="welcome-points">
                {commonContent}

                {isEditorOrAdmin && (
                  <p>בדף הפרוטוקולים, ניתן ללחוץ על כפתור ה + בצד שמאל למטה, ולהעלות פרוטוקולים נוספים.</p>
                )}

                {isAdmin ? (
                  <>
                    <p>בדף המשתמשים, תוכלו לתחזק את רשימת הרופאים וההרשאות שלהם.</p>
                  </>
                ) : (
                  <p>
                    על מנת להוסיף יוזרים, לאפס סיסמה ולעדכן הרשאות, ניתן לפנות ל{adminName}.
                  </p>
                )}

                {!isEditorOrAdmin && (
                  <p>על מנת להוסיף פרוטוקולים, ניתן לפנות ל{editorsAndAdmins}</p>
                )}
              </div>
            </div>

          </ThemeProvider>
        </CacheProvider>
      </div>
    </CommonLayout>
  );
}

export default Welcome;
