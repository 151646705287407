import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

import { mockCases } from '../utils/mockData';
import { formatDate } from '../utils/utils';
import './Cases.css';

const CaseView = () => {
  const { id } = useParams();
  const case_ = mockCases.find((c) => c.id === id);

  if (!case_) {
    return (
      <div className="not-found">
        <h2>המקרה לא נמצא</h2>
        <Link to="/cases" className="button-primary">
          חזרה לרשימת המקרים
        </Link>
      </div>
    );
  }

  return (
    <div className="case-view-container">
      <Link to="/cases" className="back-link">
        <ChevronRight size={20} />
        חזרה לרשימה
      </Link>

      <div className="case-view-content">
        <h1 className="case-view-title">{case_.title}</h1>

        <div className="case-view-metadata">
          <div className="tags-container">
            {case_.tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
              </span>
            ))}
          </div>
          <div className="case-view-date">
            נוצר ב-{formatDate(case_.createdAt)}
          </div>
        </div>

        <div className="case-view-image">
          <img src={case_.imageUrl} alt={case_.title} />
        </div>

        <div className="case-view-description">{case_.description}</div>
      </div>
    </div>
  );
};

export default CaseView;
