import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal } from 'react-bootstrap';
import drugsData from './data/resus-drugs-definitions.json';
import { ProtocolDefinition } from './types';
import ProtocolForm from './ProtocolForm';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface ProtocolsTableRef {
  getProtocols: () => ProtocolDefinition[];
}

const ProtocolsTable = forwardRef<ProtocolsTableRef, {}>((_,ref) => {
  const getInitialProtocols = (): ProtocolDefinition[] => {
    return drugsData.protocols.map(protocol => ({
      protocolId: protocol.protocolId,
      drugs: protocol.drugs || [],
      drips: protocol.drips || [],
      defi: protocol.defi || [],
      drugComments: protocol.drugComments || {}
    }));
  };

  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editingProtocol, setEditingProtocol] = useState<ProtocolDefinition | null>(null);
  const [protocols, setProtocols] = useState<ProtocolDefinition[]>(getInitialProtocols());
  const [expandedProtocolId, setExpandedProtocolId] = useState<string | null>(null);

  useImperativeHandle(ref, () => ({
    getProtocols: () => {
      return protocols}
  }));

  const handleSubmit = (protocol: ProtocolDefinition) => {
    console.log ('*** handle sumbit', {editingProtocol})
    if (editingProtocol) {
       setProtocols((prev) =>
        prev.map(p => p.protocolId === editingProtocol.protocolId ? protocol : p
      ));
    } else {
      // Add new protocol
      setProtocols((prev) => [...prev, protocol]);
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingProtocol(null);
  };

  
  const handleEdit = (protocol: ProtocolDefinition) => {
    setEditingProtocol(protocol);
    setShowModal(true);
  };

  const handleDelete = (protocolId: string) => {
    if (window.confirm('Are you sure you want to delete this protocol?')) {
      setProtocols(protocols.filter(p => p.protocolId !== protocolId));
    }
  };

  const filteredProtocols = protocols.filter((protocol) =>
    protocol.protocolId.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <input
          type="text"
          className="form-control w-50"
          placeholder="Search by protocol ID..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
        >
          הוסף פרוטוקולים 
        </button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editingProtocol ? 'Edit Protocol' : 'Add New Protocol'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProtocolForm
            onSubmit={handleSubmit}
            onCancel={handleCloseModal}
            editingProtocol={editingProtocol}
          />
        </Modal.Body>
      </Modal>

      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>פרוטוקול</th>
              <th>פעולות</th>
            </tr>
          </thead>
          <tbody>
            {filteredProtocols.map((protocol) => (
              <React.Fragment key={protocol.protocolId}>
                <tr>
                  <td
                    onClick={() => setExpandedProtocolId(
                      expandedProtocolId === protocol.protocolId ? null : protocol.protocolId
                    )}
                    style={{ cursor: 'pointer' }}
                  >
                    {protocol.protocolId} 
                  </td>
                  <td>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                      <FontAwesomeIcon 
                        icon={faEdit} 
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEdit(protocol)} // Open modal for editing
                        // onClick={() => setEditingMedicine(medicine)} // Open form with selected medicine
                      />
                      <FontAwesomeIcon 
                        icon={faTrash} 
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(protocol.protocolId)} // Delete the selected medicine
                      />
                    </div>
                  </td>
                </tr>
                {expandedProtocolId === protocol.protocolId && (
                  <tr>
                    <td colSpan={2} className="border-0 bg-light">
                      <div className="p-3">
                        {protocol.drugs && protocol.drugs.length > 0 && (
                          <div className="mb-3">
                            <h6>Drugs</h6>
                            <ul className="list-group">
                            {protocol.drugs.map((drug, index) => {
                                const commentText = protocol.drugComments?.[drug]; 

                                return (
                                  <li key={index} className="list-group-item">
                                    <strong>{drug}</strong>
                                    {commentText && (
                                      <span className="ms-2 text-muted"> - {commentText}</span>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                        
                        {protocol.drips && protocol.drips.length > 0 && (
                          <div className="mb-3">
                            <h6>Drips</h6>
                            <ul className="list-group">
                            {protocol.drips.map((drug, index) => {
                                const commentText = protocol.drugComments?.[drug]; 

                                return (
                                  <li key={index} className="list-group-item">
                                    <strong>{drug}</strong>
                                    {commentText && (
                                      <span className="ms-2 text-muted"> - {commentText}</span>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                        
                        {protocol.defi && protocol.defi.length > 0 && (
                          <div className="mb-3">
                            <h6>Defibrillation</h6>
                            <ul className="list-group">
                              {protocol.defi.map((def, index) => (
                                <li key={index} className="list-group-item">
                                  <strong>{def.name}</strong>
                                  <span className="ms-2">{def.joulePerKg} J/kg</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default ProtocolsTable;